import React from "react";
import PropTypes from "prop-types";
import {motion} from 'framer-motion';
import ExtrapolateTop from "../constants/extrapolate-top";
import {SVG_ICON_DANGER, SVG_ICON_INFO, SVG_ICON_SUCCESS, SVG_ICON_WARNING} from "./AppSvg";

const steps = {
    0: {svgIcon: null, backgroundAlert: "", colorText: ""},
    1: {svgIcon: null, backgroundAlert: "alert-dark", colorText: "text-dark"},
    2: {svgIcon: ["bg-info", <SVG_ICON_INFO/>], backgroundAlert: "alert-primary", colorText: "text-primary"},
    3: {svgIcon: ["bg-success", <SVG_ICON_SUCCESS/>], backgroundAlert: "alert-success", colorText: "text-success"},
    4: {svgIcon: ["bg-warning", <SVG_ICON_WARNING/>], backgroundAlert: "alert-warning", colorText: "text-warning"},
    5: {svgIcon: ["bg-danger", <SVG_ICON_DANGER/>], backgroundAlert: "alert-danger", colorText: "text-danger"},
};

const MessageBanner = ({type, is_fixed, has_closed, action_closed, text, code}) => {
    let currentStep = (code !== undefined) ? steps[code] : '';
    let classMessage = ((currentStep.backgroundAlert ?? '') + ' ' + (currentStep.colorText ?? ''));
    if (classMessage !== '' && is_fixed) classMessage += ' message-fixed';

    if (type === "notification") {
        return (
            <motion.div
                style={{top: ExtrapolateTop(document.querySelector(".app-container").style.transform)}}
                initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5}}
                className={"notification" + classMessage}>
                {has_closed && <button type={"button"} className="delete" onClick={action_closed}/>}
                {text}
            </motion.div>
        );
    }
    else if (type === "banner") {
        return (
            <div role="alert" className={"fade d-flex align-items-center alert mb-0 " + classMessage + ' ' + (code === undefined ? 'd-none' : 'show')}>
                {currentStep.svgIcon && <div className={currentStep.svgIcon[0] + " me-3 icon-item"}>{currentStep.svgIcon[1]}</div>}
                <div className="flex-1"><p className="mb-0">{text}</p></div>
            </div>
        );
    } else if (type === "linear") {
        return (
            <div className={"message-body-linear" + classMessage}>{text}</div>
        );
    }
};

MessageBanner.propTypes = {
    type: PropTypes.string.isRequired,
    is_fixed: PropTypes.bool.isRequired,
    has_closed: PropTypes.bool.isRequired,
};

export default MessageBanner;
