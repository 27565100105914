import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useGlobalContext} from "../../constants/application-context";
import AppBreadcrumb from "../../utils/AppBreadcrumb";
import {AuthAPI} from "../../apis/AuthAPI";
import AppPlaceholder from "../../utils/AppPlaceholder";
import {SVG_EDIT, SVG_PUZZLE} from "../../utils/AppSvg";
import IconCategory from "../../assets/images/icon-category.png";
import IconPlate from "../../assets/images/icon-plate.png";
import Modal from "../modal";
import ModalContainer from "../modal_container";
import FormMenuCategory from "../form/menu_category";
import HandlerStatusArchive from "../../constants/handle-status-archive";
import {getFileUrl} from "../../utils/Utils";
import HandlerRemoveFile from "../../constants/handle-remove-file";

const RenderListConsumable = ({item}) => {
    let image_menu_consumable = getFileUrl(item, 'url_menu_consumable_cover', IconPlate);
    return (
        <header>
            <Link to={"/menu-consumable/" + item.id}
                  className={"d-flex justify-content-center align-items-center bg-primary rounded-1 ps-3"}>
                <div className="flex-1">
                    <h6 className="fs-0 mb-1 text-uppercase text-white fw-bold">{item.name}</h6>
                </div>
                <img src={image_menu_consumable}
                     className="img-fluid rounded-1 d-none d-md-block zone-image zone-square zone-square-45"
                     alt={item.name}/>
            </Link>
        </header>
    );
}

const RenderCategory = ({data}) => {
    const [selectedModal, setSelectedModal] = useState(null);

    let item = data.single ?? null;
    let list_menu = data.list_menu ?? null;
    let image_menu_category = getFileUrl(item, 'url_menu_category_cover', IconCategory);
    const [checkIsActive, setCheckIsActive] = useState(!!item.is_active);

    function submitStatusActive(e, id) {
        e.preventDefault();
        let token = localStorage.getItem("token") ?? null;
        let tokenStore = localStorage.getItem("store_id_selected") ?? null;
        let dataForm = {ref_id: id, type: 'menu-category'};
        AuthAPI.statusActive(token, tokenStore, dataForm).then(r => setCheckIsActive(!checkIsActive));
    }

    return (
        <>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className="p-0 mb-3 card">
                        <div className="position-relative">
                            <img src={image_menu_category} className="rounded-1 zone-image zone-rectangle-2 w-100" alt={data.name}/>
                            {
                                image_menu_category !== IconCategory &&
                                <HandlerRemoveFile handlerRefreshData={image_menu_category} type={'cover'} />
                            }
                        </div>
                        <div className="overflow-hidden card-body">
                            <div className="flex-center row">
                                <div className="col">
                                    <div className="d-flex">
                                        <div className="calendar">
                                            <SVG_PUZZLE/>
                                        </div>
                                        <div className="fs--1 ms-2 flex-1">
                                            <h5 className="fs-0 text-capitalize">{item.name}</h5>
                                            <p className="fs--1 mt-1">Creato il {item.created_at}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pe-3 col-auto">
                                    <label className="switch">
                                        <input type="checkbox" checked={checkIsActive} onChange={(e) => submitStatusActive(e,item.id)}/>
                                        <div className="slider"></div>
                                        <div className="slider-card">
                                            <div className="slider-card-face slider-card-front"></div>
                                            <div className="slider-card-face slider-card-back"></div>
                                        </div>
                                    </label>
                                </div>
                                <div className="d-flex justify-content-end col-12 mt-3">
                                    <div className="px-0 col-auto">
                                        <button type="button" className="px-4 px-sm-5 btn btn-falcon-default btn-sm" onClick={() => setSelectedModal(data)}>
                                            <SVG_EDIT/>
                                            <span className="d-none d-xl-inline-block ms-1">Modifica</span>
                                        </button>
                                    </div>
                                    <div className="ps-3 col-auto">
                                        <HandlerStatusArchive isArchive={(item.is_archive === 1)} typeHandle={'menu-category'} refId={item.id} item={item}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-12"}>
                    <div className="card">
                        <div className="card-body">
                            <p>{item.description ?? 'Nessuna descrizione'}</p>
                            <h5 className="fs-0 mt-5 mb-2">Puoi trovare la categorie nei seguenti menù</h5>
                            {
                                list_menu && list_menu.map((item, key) =>
                                    <Link key={key.toString()} to={"/menu/" + item.id}
                                          className="badge border link-dark text-decoration-none me-1">{item.name}</Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ModalContainer>
                {
                    selectedModal &&
                    <Modal handleClose={() => setSelectedModal(null)} item={{name: 'category', title: item.name, action: 'edit-category'}}>
                        <div className={"modal-container card overflow-hidden"}>
                            <div className="card-header"><h4 className="font-alt fs-1 modal-title color-brand-dark col-12 text-center">Modifica Menu Category</h4></div>
                            <FormMenuCategory id={item.id} handleClose={() => setSelectedModal(null)}/>
                        </div>
                    </Modal>
                }
            </ModalContainer>
        </>
    );
}

const SingleMenuCategory = () => {
    const {id} = useParams();
    const global = useGlobalContext();
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let data = {menu_category_id: id};
            let responseData = await AuthAPI.menuCategoryItem(global.token, global.tokenStore, data);
            // convert data to json
            if (responseData.status === 200) setData(responseData.data);
        }

        fetchData().then(() => setLoadingData(false));
    }, [global.token, global.tokenStore, id, loadingData]);

    return (
        <>
            <div className={"row mt-3"}>
                <AppBreadcrumb>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/dashboard"}>Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to={"/dashboard/menu-category"} role="button" tabIndex="0">Lista Categorie</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Categoria</li>
                    </ol>
                </AppBreadcrumb>
                <div className={"col-md-8"}>
                    {
                        loadingData
                            ?
                            <div className={"row"}>
                                <div className={"col-12 mb-3"}><AppPlaceholder withMedia={true}/></div>
                                <div className={"col-12"}><AppPlaceholder/></div>
                            </div>
                            : <RenderCategory data={data.context}/>
                    }
                </div>
                <div className={"col-md-4"}>
                    <div className="card">
                        <div className="bg-light card-header">
                            <h6 className="mb-0">Le portate</h6>
                        </div>
                        <div className="fs--1 border-bottom card-body">
                            <div className={"consumable-content"}>
                                {
                                    loadingData
                                        ? <AppPlaceholder classCSS={"card-no-box"}/>
                                        : data.context.list_menu_consumable.map((item, key) => {
                                            return <RenderListConsumable key={key.toString()} item={item}/>
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleMenuCategory;