import './index.css';
import React from 'react';
import {useGlobalContext} from "../../constants/application-context";

const Footer = () => {
    const global = useGlobalContext();

    let textMission = 'Diamo forza alle attività del food business, colmando il gap con i grandi marketplace. Aiutando ad ottenere di più dai loro prodotti, sviluppiamo nuove soluzioni per offrire ai loro clienti i migliori prodotti del territorio.';
    /*
    let htmlSocial =
        <div className="icon-group mt-4">
            <a className="icon-item bg-white text-facebook" href="#!"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" className="svg-inline--fa fa-facebook-f fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg></a>
            <a className="icon-item bg-white text-twitter" href="#!"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg></a>
            <a className="icon-item bg-white text-google-plus" href="#!"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-plus-g" className="svg-inline--fa fa-google-plus-g fa-w-20 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M386.061 228.496c1.834 9.692 3.143 19.384 3.143 31.956C389.204 370.205 315.599 448 204.8 448c-106.084 0-192-85.915-192-192s85.916-192 192-192c51.864 0 95.083 18.859 128.611 50.292l-52.126 50.03c-14.145-13.621-39.028-29.599-76.485-29.599-65.484 0-118.92 54.221-118.92 121.277 0 67.056 53.436 121.277 118.92 121.277 75.961 0 104.513-54.745 108.965-82.773H204.8v-66.009h181.261zm185.406 6.437V179.2h-56.001v55.733h-55.733v56.001h55.733v55.733h56.001v-55.733H627.2v-56.001h-55.733z"></path></svg></a>
            <a className="icon-item bg-white text-linkedin" href="#!"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" className="svg-inline--fa fa-linkedin-in fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg></a>
            <a className="icon-item bg-white text-gray-700" href="#!"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="medium-m" className="svg-inline--fa fa-medium-m fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M71.5 142.3c.6-5.9-1.7-11.8-6.1-15.8L20.3 72.1V64h140.2l108.4 237.7L364.2 64h133.7v8.1l-38.6 37c-3.3 2.5-5 6.7-4.3 10.8v272c-.7 4.1 1 8.3 4.3 10.8l37.7 37v8.1H307.3v-8.1l39.1-37.9c3.8-3.8 3.8-5 3.8-10.8V171.2L241.5 447.1h-14.7L100.4 171.2v184.9c-1.1 7.8 1.5 15.6 7 21.2l50.8 61.6v8.1h-144v-8L65 377.3c5.4-5.6 7.9-13.5 6.5-21.2V142.3z"></path></svg></a>
        </div>;
        */

    let htmlFooterCaption =
        <>
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 text-start">
                <p className={"mb-0 text-600 " + ((global.token === null) ? "text-white" : "text-dark")}>💡 Ideato e sviluppato da <a href="https://manuelbraghin.com" target={"_blank"} rel={"noreferrer"} className={"text-dark"} >Manuel Braghin</a><span className="d-inline-block mx-1">|</span><br className="d-sm-none"/>{new Date().getFullYear()} © gloowe srl p.iva 11731310014<span className="d-inline-block mx-1">| v.beta</span></p>
            </div>
            <div className="col-12 col-sm-6 mb-0 text-center text-md-end">
                <a href="https://www.iubenda.com/privacy-policy/69713275/cookie-policy"
                   className="gloowe-iubenda iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                   title="Cookie Policy ">Cookie Policy</a>
                <a href="https://www.iubenda.com/privacy-policy/69713275"
                   className="gloowe-iubenda iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe mx-1"
                   style={{margin: 'auto 5px !important'}}
                   title="Privacy Policy ">Privacy Policy</a>
                {/*
                <a href="https://www.iubenda.com/termini-e-condizioni/69713275"
                   className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
                   style={{margin: 'auto 5px !important'}}
                   title="Termini e Condizioni">Termini e Condizioni</a>
                */}
            </div>
        </>;

    if (global.token !== null)
        return (
            <footer className="footer">
                <div className="justify-content-between text-center fs--1 mt-4 mb-3 row">{htmlFooterCaption}</div>
            </footer>
        );
    else {
        return (
            <section className="overflow-hidden bg-gloowe-gradient pt-md-8 pb-0">
                <div className="container">
                    <div className={"justify-content-start align-items-center row col-12 col-md-10 m-auto row col-10 m-auto"}>
                        <div className="col-lg-6 col-12">
                            <h5 className="text-uppercase text-white fw-bold opacity-85 mb-3">Mission</h5>
                            <p className="text-white text-600">{textMission}</p>
                        </div>
                    </div>
                </div>
                <hr className="my-0 border-600 opacity-25"/>
                <div className="container py-3 text-center fs--1">
                    <div className={"justify-content-start align-items-center row col-12 col-md-10 m-auto row col-10 m-auto"}>
                        {htmlFooterCaption}
                    </div>
                </div>
            </section>
        );
    }
}

export default Footer;