import React, {useEffect, useState} from 'react';
import {AuthAPI} from "../../apis/AuthAPI";
import {useGlobalContext} from "../../constants/application-context";
import {Link} from "react-router-dom";
import AppBreadcrumb from "../../utils/AppBreadcrumb";
import AppPlaceholder from "../../utils/AppPlaceholder";
import AppInput from "../../utils/AppInput";
import {SVG_SEARCH} from "../../utils/AppSvg";
import ListMaster from "../list_master";


const CardHeader = ({data, handleSearch, sendSearchRequest}) => {
    return (
        <div className={"d-lg-flex justify-content-between"}>
            <div className="flex-between-center gy-2 px-x1 row">
                <div className="pe-0 col-auto"><h6 className="mb-0">{data.name ?? 'Elementi'}</h6></div>
                <div className="col-auto">
                    <div className="position-relative input-search-width input-group">
                        <AppInput type={"search"} structure={"field-horizontal"} labelFor={"text"}
                                  class={"rounded-2 rounded-end-0"} placeHolder={"Cerca per nome"}
                                  changeEvent={(e) => handleSearch(e)}/>
                        <button type="button" onClick={sendSearchRequest}
                                className="border-300 hover-border-secondary btn btn-outline-secondary btn-sm">
                            <SVG_SEARCH style={"fs--1"} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ListMenuDomain = () => {
    const global = useGlobalContext();
    const [data, setData] = useState([]);
    const [isDataComplete, setIsDataComplete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sendSearchRequest, setSendSearchRequest] = useState(0);
    const [searchRequest, setSearchRequest] = useState({text: ''});

    useEffect(() => {
        async function fetchData() {
            let data = {has_pagination: true, page: currentPage, search_request: searchRequest}
            let responseData = await AuthAPI.menuDomainList(global.token, global.tokenStore, data);
            // convert data to json
            if (responseData.status === 200) setData(responseData.data);
        }

        fetchData().then(() => {
            setIsDataComplete(true)
        });
    }, [global.token, global.tokenStore, sendSearchRequest]);

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setSendSearchRequest((sendSearchRequest + 1));
    }

    const onSendSearchRequest = () => {
        setCurrentPage(1);
        setSendSearchRequest((sendSearchRequest + 1));
    }

    function handleSearchData(e) {
        let currentName = e.target.name;
        let currentValue = e.target.value;

        switch (currentName) {
            case 'text':
                setSearchRequest({...searchRequest, text: currentValue});
                break;
            default:
                console.info('No type for ' + currentName);
                break;
        }
    }

    let tableData = {
        name: 'Menù Dominio',
        type: 'list-menu-domain',
        header: [
            {has_operation: false, name: 'Dominio menù', is_sorted: false},
            {has_operation: false, name: 'Link', is_sorted: false},
            {has_operation: true},
        ],
        list: (data && data.status) ? data.context.list : [],
        pagination: (data && data.status) ? data.context.pagination : null,
    }

    return (
        <>
            <div className={"row mt-3"}>
                <AppBreadcrumb>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/dashboard"}>Dashboard</Link></li>
                        <li className="breadcrumb-item active">Lista Menù Domini</li>
                    </ol>
                </AppBreadcrumb>
                <div className={"col-12 col-sm-8"}>
                    {
                        isDataComplete
                            ?
                            <ListMaster data={tableData} pageChange={onPageChange} handleSearch={handleSearchData}>
                                <CardHeader data={tableData} handleSearch={handleSearchData} sendSearchRequest={onSendSearchRequest}/>
                            </ListMaster>
                            : <AppPlaceholder rows={10}/>
                    }
                    {/*
                        isDataComplete
                            ?
                            <TableMaster data={tableData} pageChange={onPageChange} handleSearch={handleSearchData}>
                                <CardHeader data={tableData} handleSearch={handleSearchData} sendSearchRequest={onSendSearchRequest}/>
                            </TableMaster>
                            : <AppPlaceholder rows={10}/>
                    */}
                </div>
            </div>
        </>
    );
}

export default ListMenuDomain;