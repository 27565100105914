import ClientAPI from './ClientAPI';

export const AuthAPI = {
    hasValidEmail: function (email, type = 'account') {
        return ClientAPI.post(
            `/api/has-valid-email?type=` + type,
            {email: email}
        );
    },
    subscribeNewsletter: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/subscribe-newsletter`,
            data: data,
        });
    },
    registerRequest: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/register-request`,
            data: data,
        });
    },
    getRegisterRequest: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/get-register-request`,
            data: data,
        });
    },
    getRegisterInvitation: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/get-register-invitation`,
            data: data,
        });
    },
    login: function (email, password) {
        return ClientAPI.post(
            `/api/login`,
            {email: email, password: password, type: 'store'}
        );
    },
    me: function (token, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/me`,
            headers: {Authorization: 'Bearer ' + token, 'Content-Type': 'multipart/form-data'},
            data: data,
        });
    },
    logout: function (token) {
        return ClientAPI.post(
            `/api/logout`,
            null,
            {headers: {Authorization: 'Bearer ' + token}}
            //{headers: {token: token}});
        );
    },
    signup: function (account) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/signup`,
            data: account
        });
    },
    recoveryCredential: function (type, data) {
        return ClientAPI.post(
            `/api/recovery-credential/${type.toLowerCase()}`,
            data,
        );
    },
    update: function (type, data, token) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/me/update/${type.toLowerCase()}`,
            data: data,
            headers: {Authorization: 'Bearer ' + token}
        });
    },

    // TYPES
    type: function (method, data) {
        return ClientAPI.post(
            `/api/types`,
            data,
            {headers: {'type-method': method}}
        );
    },

    // API ABOUT MENU
    menuDomainItem: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-domain`,
            headers: {
                Authorization: 'Bearer ' + token,
                'header-store': token_store_id,
                'Content-Type': 'multipart/form-data'
            },
            data: data,
        });
    },
    menuDomainSave: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-domain/save`,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id},
            data: data,
        });
    },
    menuDomainList: function (token, token_store_id, data = null) {
        return ClientAPI.post(
            `/api/menu-domain/list`,
            data,
            {headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}}
        );
    },

    menuInfo: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu/info`,
            data: data,
        });
    },
    menuItem: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu`,
            headers: {
                Authorization: 'Bearer ' + token,
                'header-store': token_store_id,
                'Content-Type': 'multipart/form-data'
            },
            data: data,
        });
    },
    menuSave: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu/save`,
            headers: {
                Authorization: 'Bearer ' + token,
                'header-store': token_store_id,
                'Content-Type': 'multipart/form-data'
            },
            data: data,
        });
    },
    menuList: function (token, token_store_id, data = null) {
        return ClientAPI.post(
            `/api/menu/list`,
            data,
            {headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}}
        );
    },

    menuCategoryItem: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-category`,
            headers: {
                Authorization: 'Bearer ' + token,
                'header-store': token_store_id,
                'Content-Type': 'multipart/form-data'
            },
            data: data,
        });
    },
    menuCategorySave: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-category/save`,
            headers: {
                Authorization: 'Bearer ' + token,
                'header-store': token_store_id,
                'Content-Type': 'multipart/form-data'
            },
            data: data,
        });
    },
    menuCategoryList: function (token, token_store_id, data = null) {
        return ClientAPI.post(
            `/api/menu-category/list`,
            data,
            {headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}}
        );
    },

    menuConsumableItem: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-consumable`,
            headers: {
                Authorization: 'Bearer ' + token,
                'header-store': token_store_id,
                'Content-Type': 'multipart/form-data'
            },
            data: data,
        });
    },
    menuConsumableSave: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-consumable/save`,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id, 'Content-Type': 'multipart/form-data'},
            data: data,
        });
    },
    menuConsumableList: function (token, token_store_id, data) {
        return ClientAPI.post(
            `/api/menu-consumable/list`,
            data,
            {headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}}
        );
    },

    // API MENU ORDER
    menuOrderItem: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-order`,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id},
            data: data,
        });
    },
    menuOrderSave: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-order/save`,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id},
            data: data,
        });
    },
    menuOrderList: function (token, token_store_id, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-order/list`,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id},
            data: data,
        });
    },
    menuOrderRemove: function (token, token_store_id, data) {
        return ClientAPI.post(
            `/api/menu-order/remove`,
            data,
            {headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}}
        );
    },
    menuOrderProcessingStatus: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/menu-order/processing`,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id},
            data: data,
        });
    },

    // API ORDER PAYMENT
    orderPayment: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/order-payment`,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id},
            data: data,
        });
    },

    // API ARCHIVE
    statusArchive: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/status-archive`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
    // API ACTIVE (ONLINE - OFFLINE)
    statusActive: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/status-active`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
    // API REMOVE MEDIA
    removeMedia: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `/api/remove-media`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },

    // API STORE
    myStore: function (token, token_store_id, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
    myStoreUpdate: function (type, token, token_store_id, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store/update/${type.toLowerCase()}`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id, 'Content-Type': 'multipart/form-data'}
        });
    },
    myStoreShopHours: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `api/store/shop-hours`,
            data: data,
        });
    },
    myStoreSettings: function (data) {
        return ClientAPI.request({
            method: "POST",
            url: `api/store/settings`,
            data: data,
        });
    },
    myStoreBillingInfo: function (token, token_store_id, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store/billing-info`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
    myStoreAddress: function (data){
        return ClientAPI.request({
            method: "POST",
            url: `api/store/address`,
            data: data,
        });
    },
    myStoreAddressRemove: function (token, token_store_id, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store/remove-address`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
    myStoreSubscriptionPlan: function (token, token_store_id, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store/subscription-plan`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
    myStoreSubscriptionPlanSave: function (token, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store/subscription-plan/save`,
            data: data,
            headers: {Authorization: 'Bearer ' + token}
        });
    },
    myStoreTeam: function (token, token_store_id, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store/team`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
    myStoreTeamSave: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store/team/save`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
    myStoreTeamEdit: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store/team/edit`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
    myStoreTeamRemove: function (token, token_store_id, data) {
        return ClientAPI.request({
            method: "POST",
            url: `api/my-store/team/remove`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },

    // ANALYTICS
    analytics: function (token, token_store_id, type, data = null) {
        return ClientAPI.request({
            method: "POST",
            url: `api/analytics/${type.toLowerCase()}`,
            data: data,
            headers: {Authorization: 'Bearer ' + token, 'header-store': token_store_id}
        });
    },
}

// {headers: {Authorization: 'Bearer ' + global.token}}
