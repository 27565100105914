import React, {useRef, useState} from "react";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import {SVG_DOWNLOAD, SVG_EXPORT} from "../../utils/AppSvg";
import ModalContainer from "../modal_container";
import Modal from "../modal";
import QrCode from "../qr_code";
import {getFileUrl} from "../../utils/Utils";
import IconMenu from "../../assets/images/icon-menu.png";
import IconCategory from "../../assets/images/icon-category.png";

const ItemMenuDomain = ({data}) => {
    const itemRef = useRef();
    const [selectedModal, setSelectedModal] = useState(null);
    let link_url = data.url ? "//".concat(data.url).concat('?from=dashboard') : "//gloowe.com/".concat(data.layout);
    let link_name = data.url ? data.url : data.layout;
    return (
        <motion.div ref={itemRef} className="card mb-3"
                    initial={{opacity: 0}} animate={{opacity: 1}} transition={{opacity: {duration: 0.35}}}>
            <div className={"row m-0"}>
                <div className={"col-12 col-md-9 p-3"}>
                    <Link to={"/domain/" + data.id}>
                        <strong className={"p-x-1"}
                                style={{justifyContent: 'center', alignItems: 'center'}}>{data.name}</strong>
                    </Link>
                    <div className={"mt-1"}>
                        <label className={"me-1 fw-semi-bold"}>Menu:</label>
                        <a href={link_url} target={"_blank"} rel="noreferrer"><span className="badge bg-info">{link_name}<SVG_EXPORT style={"ms-1"}/></span></a>
                    </div>
                    {
                        data.token && data.token.length > 0 &&
                        <div className={"mt-1"}>
                            <label className={"me-1 fw-semi-bold"}>Token:</label>
                            <span className="badge bg-gloowe-orange">{data.token}</span>
                        </div>
                    }
                </div>
                <div className={"col-12 col-md-3 p-3"}>
                    <div className={"row"}>
                        <div className={"col-6 col-md-12 mt-md-3"}>
                            <Link to={"/domain/" + data.id} type="button"
                                  className="btn btn-falcon-default btn-sm w-100">
                                <span className="d-inline-block">Apri</span>
                            </Link>
                        </div>
                        <div className={"col-6 col-md-12 mt-md-3"}>
                            <button type="button" onClick={() => setSelectedModal(data)}
                                    className="btn btn-info btn-sm w-100">
                                <SVG_DOWNLOAD/>
                                <span className="d-inline-block ms-1">QR Code</span>
                            </button>
                        </div>
                        <ModalContainer>
                            {
                                selectedModal &&
                                <Modal handleClose={() => setSelectedModal(null)} item={{name: 'QR Code', title: 'QR Code | ' + data.name, action: 'edit-domain'}}>
                                    <div className={"modal-container card overflow-hidden"}>
                                        <QrCode name={link_name} url={link_url}/>
                                    </div>
                                </Modal>
                            }
                        </ModalContainer>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
const ItemMenu = ({data}) => {
    const itemRef = useRef();
    let image_menu_logo = getFileUrl(data, 'url_menu_logo', IconMenu);
    return (
        <motion.div ref={itemRef} className={"card mb-3"}
                    initial={{opacity: 0}} animate={{opacity: 1}} transition={{opacity: {duration: 0.35}}}>
            <div className={"row m-0"}>
                <div className={"col-12 col-md-10 p-0"}>
                    <div className={"d-flex flex-row justify-content-start"}>
                        <div className={"col-auto"}>
                            <img src={image_menu_logo} className="img-fluid rounded-start d-block zone-image zone-square zone-square-125" alt={data.name}/>
                        </div>
                        <div className={"p-3"}>
                            <strong className={"p-x-1 mb-2"} style={{justifyContent: 'center', alignItems: 'center'}}>{data.name}</strong>
                            <div className={"mt-2"}>
                                {
                                    data.is_active
                                        ? <div className="badge badge-soft-success">Online</div>
                                        : <div className="badge badge-soft-warning">Offline</div>
                                }
                            </div>
                            <div className={"mt-2"}>
                                <span className="badge badge-soft-info">Categorie: {data.tot_category ?? 0}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-12 col-md-2 p-3"}>
                    <div className={"row"}>
                        <div className={"col-6 col-md-12 mt-md-3"}>
                            <Link to={"/menu/" + data.id} type="button" className="btn btn-falcon-default btn-sm w-100">
                                <span className="d-inline-block">Apri</span>
                            </Link>
                        </div>
                        <div className={"col-6 col-md-12 mt-md-3"}>
                            <Link to={"/pdf/" + data.id} target={"_blank"} rel="noreferrer" className="btn btn-info btn-sm w-100">
                                <SVG_EXPORT />
                                <span className="d-inline-block ms-1">PDF</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
const ItemMenuCategory = ({data}) => {
    const itemRef = useRef();
    let image_menu_category = getFileUrl(data, 'url_menu_category_cover', IconCategory);
    return (
        <motion.div ref={itemRef} className="card mb-3"
                    initial={{opacity: 0}} animate={{opacity: 1}} transition={{opacity: {duration: 0.35}}}>
            <div className={"row m-0"}>
                <div className={"col-12 col-md-10 p-0"}>
                    <div className={"d-flex flex-row justify-content-start"}>
                        <div className={"col-auto d-none d-md-block"}>
                            <img src={image_menu_category} className="img-fluid rounded-start d-block zone-image zone-rectangle-2 zone-sm" alt={data.name}/>
                        </div>
                        <div className={"p-3"}>
                            <strong className={"p-x-1 mb-2"} style={{justifyContent: 'center', alignItems: 'center'}}>{data.name}</strong>
                            <div className={"mt-2"}>
                                {
                                    data.is_active
                                        ? <div className="badge badge-soft-success">Online</div>
                                        : <div className="badge badge-soft-warning">Offline</div>
                                }
                            </div>
                            <div className={"mt-2"}>
                                <span className="badge badge-soft-info">Piatti: {data.tot_consumable ?? 0}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-12 col-md-2 p-3"}>
                    <div className={"d-flex flex-column justify-content-between"}>
                        <Link to={"/menu-category/" + data.id} type="button" className="me-2 btn btn-falcon-default btn-sm">
                            <span className="d-inline-block">Apri</span>
                        </Link>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export {ItemMenuDomain, ItemMenu, ItemMenuCategory}