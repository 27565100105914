import React from "react";

const AppPlaceholder = ({withMedia, rows = 10, classCSS = ''}) => {
    const columns = Array(12);
    return (
        <div className={"card card-placeholder " + classCSS}>
            {
                withMedia &&
                <div className="p-0 card-header" style={{width: '100%', height: '180px'}}>
                    <span className="placeholder w-100 h-100"></span>
                </div>
            }
            <div className="card-body">
                <div className="mb-2 placeholder-glow card-title h5">
                    <span className="col-6 placeholder"></span>
                </div>
                <p className="placeholder-glow card-text">
                    {
                        [...Array(rows + 1)].map((item, key) =>
                            <span key={key.toString()} className={"col-" + (Math.floor(Math.random() * columns.length)) + " placeholder"}></span>
                        )
                    }
                </p>
            </div>
        </div>
    )
}

AppPlaceholder.defaultProps = {
    withMedia: false,
    rows: 10
}

export default AppPlaceholder;