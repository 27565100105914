import './index.css';
import React, {useEffect, useState} from "react";
import {useGlobalContext} from "../../constants/application-context";
import {AuthAPI} from "../../apis/AuthAPI";
import AppInput from "../../utils/AppInput";
import Dropzone from "../dropzone";
import AppButton from "../../utils/AppButton";
import MessageBanner from "../../utils/MessageBanner";
import InputValidation from "../../constants/input-validation";
import HandlerAPIMessage from "../../constants/handle-api-message";
import {SVG_CIRCLE_REMOVE, SVG_PLUS} from "../../utils/AppSvg";
import AppPlaceholder from "../../utils/AppPlaceholder";
import {getFileUrl} from "../../utils/Utils";

const LayoutListAllergens = ({type, data, currentData = null, changeEvent}) => {
    if (data !== null && data.length > 0) {
        let jsonCurrentData = currentData !== null ? JSON.parse(currentData) : [];
        return (
            <>
                {
                    data.map((item, key) =>
                        <div key={key.toString()} data-name={type} data-value={item.id}
                             className={"me-2 cursor-pointer badge " + ((jsonCurrentData.some(jsonItem => parseInt(item.id) === parseInt(jsonItem))) ? 'bg-info' : 'badge-soft-secondary')}
                             onClick={changeEvent}>{item.name}</div>
                    )
                }
            </>
        );
    } else {
        return (<div className={"col-12"}><AppPlaceholder rows={3} classCSS={"card-no-box"}/></div>);
    }
}

const LayoutListMenuCategory = ({type, data, currentData = null, changeEvent}) => {
    if (data !== null && data.length > 0) {
        let jsonCurrentData = currentData !== null ? JSON.parse(currentData) : [];
        return (
            <>
                {
                    data.map((item, key) =>
                        <div key={key.toString()} data-name={type} data-value={item.id}
                             className={"d-flex align-items-center rounded-3 bg-light p-3 mb-2 border cursor-pointer " + ((jsonCurrentData.some(jsonItem => parseInt(item.id) === parseInt(jsonItem))) ? 'border-primary' : '')}
                             onClick={changeEvent}>
                            <h6 className="mb-0 fw-bold">{item.name}</h6>
                        </div>
                    )
                }
            </>
        );
    } else {
        return (<div className={"col-12"}><AppPlaceholder rows={2} classCSS={"card-no-box"}/></div>);
    }
}

const LayoutVariationPrice = ({data, currentData = null, changeEvent}) => {
    return (
        <div className="p-0">
            <table className="bg-white mb-2 mt-1 table table-bordered">
                <thead>
                <tr className="fs--1">
                    <th scope="col"></th>
                    <th scope="col">Variabile</th>
                    <th scope="col">Prezzo</th>
                </tr>
                </thead>
                <tbody className="">
                {
                    data && data.map((item, key) =>
                        <tr key={key.toString()}>
                            <td>
                                <button type="button" className="btn btn-link btn-sm flex-1"
                                        data-name={"list_variation_button"} data-operation={"remove"} data-key={item.index}
                                        onClick={changeEvent}><SVG_CIRCLE_REMOVE/></button>
                            </td>
                            <td>
                                <input type="text" id={"variation_text_" + item.index} name={"variation_text_" + item.index}
                                       placeholder={"Dimensioni/Colore"} className="form-control form-control-sm"
                                       data-name={"list_variation"} data-variation={true}
                                       onChange={changeEvent} value={item.text}/>
                            </td>
                            <td>
                                <div className="input-group input-group-sm">
                                    <span className="d-none d-sm-block input-group-text">€</span>
                                    <input type="number" id={"variation_price_" + item.index}
                                           name={"variation_price_" + item.index}
                                           className="form-control" step="0.01" min={0}
                                           data-name={"list_variation"} data-variation={true}
                                           onChange={changeEvent} value={item.price ?? 0}/>
                                </div>
                            </td>
                        </tr>
                    )
                }
                <tr>
                    <td colSpan={3} className={"text-center"}>
                        <button type="button" className="btn btn-falcon-default btn-sm w-100"
                                data-name={"list_variation_button"} data-operation={"add"}
                                onClick={changeEvent}><SVG_PLUS/>Aggiungi
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

const FormMenuItem = (props) => {
    let id = props.id ?? null;
    const global = useGlobalContext();
    const [apiStatus, setApiStatus] = useState({})
    const [onSubmitForm, setOnSubmitForm] = useState(false)
    const [submitFormStatus, setSubmitFormStatus] = useState(false)
    const [isDataComplete, setIsDataComplete] = useState(false)
    const [preview, setPreview] = useState({})
    const [listFoodAllergens, setListFoodAllergens] = useState({})
    const [listMenuCategory, setListMenuCategory] = useState({})
    const [dataMenuConsumable, setDataMenuConsumable] = useState({
        id: null,
        name: '',
        description: '',
        price: 0,
        meta_data: '',
        file_cover: '',
        list_allergen_id: '',
        list_menu_category_id: '',
        has_variation: false,
        list_variation: [{index: 1, text: '', price: 0}],
    });

    useEffect(() => {
        async function fetchData() {
            let responseDataItem, responseDataCategoryList, responseDataAllergen;
            if (id !== null) {
                responseDataItem = await AuthAPI.menuConsumableItem(global.token, global.tokenStore, {menu_consumable_id: id});
                if (responseDataItem.status === 200) {
                    let data_list_menu_category_id = [];
                    let data = responseDataItem.data.status ? responseDataItem.data.context.single : null;
                    let list_category = responseDataItem.data.status ? responseDataItem.data.context.list_category : null;
                    if (list_category) list_category.map(item => data_list_menu_category_id.push(item.id.toString()));

                    let image_menu_consumable = getFileUrl(data, 'url_menu_consumable_cover');
                    if (image_menu_consumable !== null) setPreview(image_menu_consumable);

                    let list_variation = [{index: 1, text: '', price: 0}];
                    let has_variation = !(data.num_variations === 0);
                    if (has_variation) {
                        list_variation = [];
                        for (let i = 0; i < data.list_variation.length; i++) {
                            let item = data.list_variation[i];
                            list_variation.push({index: (i + 1), text: item.name, price: item.price});
                        }
                    }

                    setDataMenuConsumable({
                        ...dataMenuConsumable,
                        id: data.id,
                        name: data.name,
                        description: data.description,
                        price: data.price,
                        meta_data: data.price,
                        list_allergen_id: data.list_allergen_id,
                        list_menu_category_id: ((data_list_menu_category_id.length > 0) ? JSON.stringify(data_list_menu_category_id) : ''),
                        has_variation: has_variation,
                        list_variation: list_variation
                    });
                }
            }
            responseDataAllergen = await AuthAPI.type('ALLERGEN');
            if (responseDataAllergen.status === 200) setListFoodAllergens(responseDataAllergen.data.status ? responseDataAllergen.data.context.list : null);

            responseDataCategoryList = await AuthAPI.menuCategoryList(global.token, global.tokenStore);
            return (responseDataCategoryList.status === 200) ? responseDataCategoryList.data : null;
        }

        fetchData().then((responseContext) => {
            setIsDataComplete(true);
            setIsDataComplete(responseContext !== null);
            if (responseContext !== null) {
                setListMenuCategory(responseContext.status ? responseContext.context.list : null);
            }
        });
    }, []);

    function handleData(e) {
        //let currentType = e.target.type;
        let currentName = (e.currentTarget.tagName === 'DIV') ? e.currentTarget.dataset.name : ((e.currentTarget.tagName === 'BUTTON') ? e.currentTarget.dataset.name : e.target.name);
        let currentValue = (currentName === 'has_variation') ? !dataMenuConsumable.has_variation : ((e.currentTarget.tagName === 'DIV') ? e.currentTarget.dataset.value : e.target.value);
        let currentOperation, currentKey = null;

        if (e.currentTarget.type === 'file') currentValue = e.target.files[0];
        if (e.currentTarget.tagName === 'DIV' && currentName === 'list_allergen_id') {
            if (e.currentTarget.classList.contains('bg-info')) {
                e.currentTarget.classList.remove('bg-info');
                e.currentTarget.classList.add('badge-soft-secondary');
            } else {
                e.currentTarget.classList.remove('badge-soft-secondary');
                e.currentTarget.classList.add('bg-info');
            }
        } else if (e.currentTarget.tagName === 'BUTTON' && currentName === 'list_variation_button') {
            currentKey = e.currentTarget.dataset.key ?? null;
            currentValue = dataMenuConsumable.list_variation;
            currentOperation = e.currentTarget.dataset.operation ?? null;
            switch (currentOperation) {
                case 'add':
                    currentValue.push({index: (currentValue.length + 1), text: '', price: 0});
                    setDataMenuConsumable({...dataMenuConsumable, list_variation: currentValue});
                    break;
                case 'remove':
                    currentValue = currentValue.filter(object => {
                        return object.index !== parseInt(currentKey);
                    });
                    setDataMenuConsumable({...dataMenuConsumable, list_variation: currentValue});
                    break;
            }
        } else if (e.currentTarget.tagName === 'INPUT' && currentName.includes('variation')) {
            currentName = 'list_variation';
        } else if (e.currentTarget.tagName === 'DIV') {
            if (e.currentTarget.classList.contains('border-primary')) e.currentTarget.classList.remove('border-primary');
            else e.currentTarget.classList.add('border-primary');
        }

        switch (currentName) {
            case 'name':
                setDataMenuConsumable({...dataMenuConsumable, name: currentValue});
                break;
            case 'description':
                setDataMenuConsumable({...dataMenuConsumable, description: currentValue});
                break;
            case 'price':
                setDataMenuConsumable({...dataMenuConsumable, price: currentValue});
                break;
            case 'file_cover':
                setDataMenuConsumable({...dataMenuConsumable, file_cover: currentValue});
                // Generate Preview
                const objectUrl = URL.createObjectURL(currentValue);
                setPreview(objectUrl);
                return () => URL.revokeObjectURL(objectUrl);
            //break;
            case 'list_menu_category_id':
                // Search on list and add or delete current value
                let currentList = (dataMenuConsumable.list_menu_category_id.length === 0) ? [] : JSON.parse(dataMenuConsumable.list_menu_category_id);
                let currentKey = currentList.indexOf(currentValue);
                if (currentKey === null || currentKey === -1) currentList.push(currentValue);
                else currentList.splice(currentKey, 1);

                currentValue = JSON.stringify(currentList);
                setDataMenuConsumable({...dataMenuConsumable, list_menu_category_id: currentValue});
                break;
            case 'list_allergen_id':
                // Search on list and add or delete current value
                let currentListAllergen = (dataMenuConsumable.list_allergen_id.length === 0) ? [] : JSON.parse(dataMenuConsumable.list_allergen_id);
                let currentKeyAllergen = currentListAllergen.indexOf(currentValue);
                if (currentKeyAllergen === null || currentKeyAllergen === -1) currentListAllergen.push(currentValue);
                else currentListAllergen.splice(currentKeyAllergen, 1);

                currentValue = JSON.stringify(currentListAllergen);
                setDataMenuConsumable({...dataMenuConsumable, list_allergen_id: currentValue});
                break;
            case 'has_variation':
                setDataMenuConsumable({...dataMenuConsumable, has_variation: currentValue});
                break;
            case 'list_variation':
                currentValue = [];
                for (let i = 1; i <= dataMenuConsumable.list_variation.length; i++) {
                    currentValue.push({
                        index: i,
                        text: document.getElementById('variation_text_' + i).value,
                        price: document.getElementById('variation_price_' + i).value,
                    });
                }
                //document.getElementById(currentObject).value = currentValue;
                setDataMenuConsumable({...dataMenuConsumable, list_variation: currentValue});
                break;
            default:
                console.info('No type for ' + currentName);
                break;
        }
    }

    function handleRemoveDataFile(e) {
        setDataMenuConsumable({...dataMenuConsumable, file_cover: ''});
        setPreview({});
    }

    const formSubmit = async e => {
        setOnSubmitForm(true);
        e.preventDefault();
        let validateName = InputValidation(dataMenuConsumable.name, 'text', 'Nome');
        let validatePrice = dataMenuConsumable.has_variation ? true : InputValidation(dataMenuConsumable.price, 'text', 'Prezzo');
        let validateListMenuCategoryId = InputValidation(dataMenuConsumable.list_menu_category_id, 'array', 'categorie');
        if (validateName === true && validatePrice === true && validateListMenuCategoryId === true) {
            let dataForm = {
                menu_consumable_id: dataMenuConsumable.id ?? null,
                name: dataMenuConsumable.name,
                description: dataMenuConsumable.description,
                price: dataMenuConsumable.has_variation ? null : dataMenuConsumable.price,
                file: dataMenuConsumable.file_cover,
                list_allergen_id: dataMenuConsumable.list_allergen_id,
                list_menu_category_id: dataMenuConsumable.list_menu_category_id,
                list_variation: dataMenuConsumable.has_variation ? JSON.stringify(dataMenuConsumable.list_variation) : null,
            };

            let response = await AuthAPI.menuConsumableSave(global.token, global.tokenStore, dataForm);
            if (response.status === 200) {
                if (response.data.status) {
                    global.setDataUpdate(!global.dataUpdate);
                    let responseContext = response.data.context !== null ? response.data.context : null;
                    let responseMessage = response.data.message !== null ? response.data.message : null;
                    if (responseContext === null || responseContext.length === 0) setApiStatus(HandlerAPIMessage(5, 'Errore imprevisto'));
                    else setApiStatus(HandlerAPIMessage(3, responseMessage));
                    setSubmitFormStatus(response.data.status);
                } else setApiStatus(HandlerAPIMessage(5, response.data.message));
            } else setApiStatus(HandlerAPIMessage(5, 'Impossibile procedere, non c\'è nessuna connessione ad internet'));
            setOnSubmitForm(false);
        } else {
            if (validateName !== true) setApiStatus(HandlerAPIMessage(5, validateName));
            else if (validatePrice !== true) setApiStatus(HandlerAPIMessage(5, validatePrice));
            else if (validateListMenuCategoryId !== true) setApiStatus(HandlerAPIMessage(5, validateListMenuCategoryId));
            setOnSubmitForm(false);
        }
    }

    if (!isDataComplete) return (<AppPlaceholder rows={20} classCSS={"card-no-box"}/>);

    if (listMenuCategory && listMenuCategory.length === 0) {
        return (
            <div className={"p-5 text-center"}>
                <div className="display-1 text-300 fs-error">OPS</div>
                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">Non puoi ancora creare una voce del menù.</p>
                <hr/>
                <p className={"mb-0"}>Qualcosa non è andato come previsto. Controlla di aver creato almeno una <b>categoria</b>.</p>
                <p>Se invece pensi ci sia un errore,<a href="mailto:team@gloowe.com" className="ms-1">contattaci</a>.</p>
            </div>
        );
    }

    return (
        <>
            {
                (!submitFormStatus)
                    ?
                    <form onSubmit={formSubmit}>
                        <div className={"row mb-3"}>
                            <div className={"col-sm-12 col-md-9"}>
                                <div className={"row"}>
                                    <div className={"col-sm-12 col-md-5"}>
                                        <AppInput
                                            type={"input"}
                                            structure={"field-vertical"}
                                            labelFor={"name"} content={"Nome"}
                                            class={"is-medium mb-3"}
                                            changeEvent={(e) => handleData(e)}
                                            placeHolder={"Dai un nome alla nuova categoria"}
                                            value={dataMenuConsumable.name}
                                        />
                                        <AppInput
                                            type={"textarea"}
                                            structure={"field-vertical"}
                                            labelFor={"description"} content={"Descrizione"}
                                            class={"is-medium mb-3"}
                                            changeEvent={(e) => handleData(e)}
                                            placeHolder={"..."}
                                            value={dataMenuConsumable.description}
                                        />
                                        <div className="field">
                                            <label className="form-label">Allergeni da segnalare</label>
                                            <div>
                                                <LayoutListAllergens type={"list_allergen_id"}
                                                                     data={listFoodAllergens}
                                                                     currentData={((dataMenuConsumable && dataMenuConsumable.list_allergen_id && dataMenuConsumable.list_allergen_id.length > 0) ? dataMenuConsumable.list_allergen_id : null)}
                                                                     changeEvent={(e) => handleData(e)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-sm-12 col-md-7"}>
                                        <div className="field d-flex hstack mb-0">
                                            <label htmlFor="url" className="form-label flex-1 mb-0">I piatto ha un unico costo?</label>
                                            <button type="button"
                                                    className={"btn btn-sm " + (dataMenuConsumable.has_variation ? "btn-success" : "btn-outline-success")}
                                                    data-name={"has_variation"}
                                                    onClick={(e) => handleData(e)}>{dataMenuConsumable.has_variation ? 'No' : 'Yes'}</button>
                                        </div>
                                        {
                                            dataMenuConsumable.has_variation
                                                ? <LayoutVariationPrice data={dataMenuConsumable.list_variation}
                                                                        currentData={(dataMenuConsumable && dataMenuConsumable.list_variation.length > 0 ? dataMenuConsumable.list_variation : null)}
                                                                        changeEvent={(e) => handleData(e)}/>
                                                :
                                                <>
                                                    <AppInput
                                                        type={"number"}
                                                        structure={"field-horizontal"}
                                                        labelFor={"price"} content={"€"}
                                                        class={"is-medium"}
                                                        changeEvent={(e) => handleData(e)}
                                                        placeHolder={"..."}
                                                        value={dataMenuConsumable.price}
                                                    />
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12 col-md-3"}>
                                <label className="form-label">Foto del piatto</label>
                                <Dropzone preview={preview} inputFileName={"file_cover"}
                                          typeZone={'zone-square zone-square-200'}
                                          fileCoverName={dataMenuConsumable.file_cover.name}
                                          onInputChange={(e) => handleData(e)}
                                          onInputRemoveFile={(e) => handleRemoveDataFile(e)}>
                                    <div className="d-flex justify-content-center">
                                        <p className="fs-0 mb-0 text-700">Aggiungi una foto</p>
                                    </div>
                                    <p className="mb-0 w-75 mx-auto text-400">Ti consigliamo di caricare un file da 300x300 px e max 3MB</p>
                                </Dropzone>
                            </div>
                            <div className={"co-12 mt-3"}>
                                <div className="field">
                                    <label className="form-label">Associa il piatto ad una o più categorie</label>
                                    <div className={"simple-bar-content-wrapper"} style={{overflowX: "scroll"}}>
                                        <div className="control d-flex inline-size-max-content">
                                            <LayoutListMenuCategory type={"list_menu_category_id"}
                                                                    data={listMenuCategory}
                                                                    currentData={(dataMenuConsumable && dataMenuConsumable.list_menu_category_id !== undefined && dataMenuConsumable.list_menu_category_id.length > 0 ? dataMenuConsumable.list_menu_category_id : null)}
                                                                    changeEvent={(e) => handleData(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <AppButton type={"submit"} disabled={onSubmitForm}
                                           class={"btn-primary " + (onSubmitForm ? 'is-loading' : '') + " is-block is-fullwidth is-medium"}>
                                    {!onSubmitForm ? (id ? "Modifica" : "Crea il nuovo piatto") : '...'}
                                </AppButton>
                            </div>
                            <div className={"col-12 pt-2"}>
                                <MessageBanner type={"banner"} is_fixed={false} has_closed={false}
                                               code={apiStatus.class} text={apiStatus.textMessage}/>
                            </div>
                        </div>
                    </form>
                    :
                    <div className="card-body text-center">
                        <h3 className="fw-semi-bold ">{id ? 'Il piatto è stato aggiornato' : 'Nuovo piatto creato'}</h3>
                        <p>{apiStatus.textMessage}</p>
                        <div className="text-center">
                            <button type="button" className="my-3 btn btn-outline-secondary btn-sm" onClick={props.handleClose}>Chiudi</button>
                            <small className="d-block">For any technical issues faced, please contact <a href="mailto:team@gloowe.com">Customer Support</a>.</small>
                        </div>
                    </div>
            }
        </>
    );
}

export default FormMenuItem;