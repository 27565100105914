import React from 'react';
import AppButton from "../../utils/AppButton";
import {SVG_REFRESH, SVG_TRASH} from "../../utils/AppSvg";

const Dropzone = (props) => {
    let classType = props.typeZone ?? 'zone-auto';
    return (
        <>
            <div className={"col-12 " + (props.preview.length > 0 ? 'd-none' : '')}>
                <div tabIndex="0" className={"dropzone-area position-relative " + classType}>
                    <input name={props.inputFileName} accept="image/*" type="file"
                           autoComplete="off" tabIndex="-1"
                           onChange={props.onInputChange}/>
                    {props.children}
                </div>
            </div>
            {
                props.preview.length > 0 && <div className={"col-12"}>
                    <div tabIndex="0" className={"zone-image position-relative " + classType}>
                        <img src={props.preview} alt={props.fileCoverName}/>
                        <div className="position-absolute" style={{bottom: '10px', right: '10px', left: '10px'}}>
                            <AppButton type={"button"} class={"btn-sm btn-warning is-block is-fullwidth is-small"} action={props.onInputRemoveFile}><SVG_REFRESH/></AppButton>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

Dropzone.defaultsValue = {
    preview: {}
}

export default Dropzone;