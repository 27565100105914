import "./create.css";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {SVG_PLUS, SVG_ROW_RIGHT} from "../../utils/AppSvg";
import ModalContainer from "../modal_container";
import Modal from "../modal";
import FormDomain from "../form/domain";
import FormMenu from "../form/menu";
import FormMenuCategory from "../form/menu_category";
import FormMenuItem from "../form/menu_item";

const CREATE_COMPONENT_ROUTER = [
    {name: 'dominio', title: 'Assegna un dominio al menù', action: 'create-domain', icon: 0},
    {name: 'menù', title: 'Crea un nuovo menù', action: 'create-menu', icon: 1},
    {name: 'categoria', title: 'Aggiungi una categoria', action: 'create-menu-category', icon: 2},
    {name: 'piatto', title: 'Aggiungi un piatto', action: 'create-menu-item', icon: 3},
];

const CreateComponentLinkItem = ({item, setModal, isMobile}) => {

    const onClickCreateComponent = (e) => {
        e.preventDefault();
        setModal(item)
    }

    if (isMobile) {
        return (
            <div className={"col-6"}>
                <Link to={item.destination} className="d-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none"
                      onClick={onClickCreateComponent}>
                    <div className="avatar avatar-2xl">
                        <div className="avatar-name rounded-circle fs-2 bg-soft-primary text-primary">
                            <span>{item.name.charAt(0)}</span>
                        </div>
                    </div>
                    <p className="mb-0 fw-medium text-800 text-truncate fs--2"><SVG_PLUS/> {item.name}</p>
                </Link>
            </div>
        );
    } else {
        return (
            <li className={"nav-item"}>
                <Link to={item.destination} className="nav-link" onClick={onClickCreateComponent}>
                    <div className="d-flex align-items-center">
                        <span className="nav-link-icon"><SVG_PLUS/></span>
                        <span className="nav-link-text ps-1">{item.name}</span>
                    </div>
                </Link>
            </li>
        )
    }
}

const CreateComponent = (props) => {
    let isMobile = props.isMobile ?? false;
    const [visible, setVisible] = useState(false);
    const [selectedModal, setSelectedModal] = useState(null);
    const [navbarScrolling, setNavbarScrolling] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    const changeNavbarCreate = () => {
        let st = window.scrollY || document.documentElement.scrollTop;
        setNavbarScrolling(window.scrollY >= 40);
        if (st !== lastScrollTop) {
            setSelectedModal(null);
            setVisible(false)
        }
        setLastScrollTop((st <= 0) ? 0 : st);
    }
    window.addEventListener('scroll', changeNavbarCreate);

    return (
        <>
            <div className={"ms-auto col-auto position-relative" + (isMobile ? '' : ' d-none d-md-block pt-1')}>
                {
                    isMobile
                        ? <button type="button" className={"btn rounded-3 btn-primary"}
                                  onClick={() => setVisible(!visible)}><SVG_PLUS/></button>
                        : <button type="button" className={"btn btn-primary"} onClick={() => setVisible(!visible)}
                                  style={{width: '110px'}}>Crea<SVG_ROW_RIGHT style={"ms-1"}/></button>
                }

                <div onMouseLeave={() => setVisible(!visible)}
                     className={"create-container card border border-primary w-100 position-fixed position-lg-absolute top-lg-0 " + (visible ? '' : 'd-none')}>
                    <div className="card-body">
                        <ContainerCreate isMobile={isMobile}>
                            {
                                CREATE_COMPONENT_ROUTER.map((item, key) =>
                                    <CreateComponentLinkItem key={key.toString()} item={item}
                                                             setModal={setSelectedModal} isMobile={isMobile}/>
                                )
                            }
                        </ContainerCreate>
                    </div>
                </div>
            </div>
            <ModalContainer>
                {
                    selectedModal &&
                    <Modal handleClose={() => setSelectedModal(null)} item={selectedModal}>
                        <div className={"modal-container card overflow-hidden"}>
                            {
                                {
                                    'create-domain':
                                        <>
                                            <div className="card-header"><h4 className="font-alt fs-1 modal-title color-brand-dark col-12 text-center">Crea Menu Domain</h4></div>
                                            <FormDomain handleClose={() => setSelectedModal(null)}/>
                                        </>,
                                    'create-menu':
                                        <>
                                            <div className="card-header"><h4 className="font-alt fs-1 modal-title color-brand-dark col-12 text-center">Crea Menu</h4></div>
                                            <FormMenu handleClose={() => setSelectedModal(null)}/>
                                        </>,
                                    'create-menu-category':
                                        <>
                                            <div className="card-header"><h4 className="font-alt fs-1 modal-title color-brand-dark col-12 text-center">Crea Menu Category</h4></div>
                                            <FormMenuCategory handleClose={() => setSelectedModal(null)}/>
                                        </>,
                                    'create-menu-item':
                                        <>
                                            <div className="card-header"><h4 className="font-alt fs-1 modal-title color-brand-dark col-12 text-center">Crea Menu Item</h4></div>
                                            <FormMenuItem handleClose={() => setSelectedModal(null)}/>
                                        </>,
                                }[selectedModal.action]
                            }
                        </div>
                    </Modal>
                }
            </ModalContainer>
        </>
    );
}

const ContainerCreate = (props) => {
    let isMobile = props.isMobile ?? false;
    if (isMobile)
        return (<div className="row">{props.children}</div>);
    else
        return (<ul className="flex-column navbar-nav">{props.children}</ul>);
}

export default CreateComponent;