import React from 'react';

const CardDefault = (props) => {
    return (
        <div className={"col-12"}>
            <div className="mb-3 card">
                <div className="position-relative card-body">
                    <div className="row">
                        <div className="col-12 text-center">
                            {props.children ?? <p>Impossibile visualizzare il testo</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardDefault;