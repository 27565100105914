import React, {useState} from 'react';
import {motion} from 'framer-motion';
import AppBrandLogo from "../../utils/AppBrandLogo";
import AppInput from "../../utils/AppInput";
import MessageBanner from "../../utils/MessageBanner";
import InputValidation from "../../constants/input-validation";
import HandlerAPIMessage from "../../constants/handle-api-message";
import {AuthAPI} from "../../apis/AuthAPI";
import BG_SHAPE_01 from "../../assets/images/bg-shape.001.png";
import BG_SHAPE_02 from "../../assets/images/bg-shape.001.png";
import AppButton from "../../utils/AppButton";

const RegisterRequest = () => {
    const [apiStatus, setApiStatus] = useState({})
    const [onSubmitForm, setOnSubmitForm] = useState(false)
    const [submitFormStatus, setSubmitFormStatus] = useState(false)
    const [dataAccountRequest, setDataAccountRequest] = useState({
        name: '',
        msisdn: '',
        email: '',
        promo_code: '',
    });

    function handleData(e) {
        let currentName = e.target.name;
        let currentValue = e.target.value;

        switch (currentName) {
            case 'name':
                setDataAccountRequest({...dataAccountRequest, name: currentValue});
                break;
            case 'msisdn':
                setDataAccountRequest({...dataAccountRequest, msisdn: currentValue});
                break;
            case 'email':
                setDataAccountRequest({...dataAccountRequest, email: currentValue});
                break;
            case 'promo_code':
                setDataAccountRequest({...dataAccountRequest, promo_code: currentValue});
                break;
            default:
                console.info('No type for ' + currentName);
                break;
        }
    }

    const formSubmit = async e => {
        setOnSubmitForm(true);
        setApiStatus(HandlerAPIMessage(undefined, null));
        e.preventDefault();
        let validateName = InputValidation(dataAccountRequest.name, 'text', 'Nome');
        let validateEmail = InputValidation(dataAccountRequest.email, 'email', 'Email');
        if (validateName === true && validateEmail === true) {
            let dataForm = {
                type: 'business',
                name: dataAccountRequest.name,
                msisdn: dataAccountRequest.msisdn ?? null,
                email: dataAccountRequest.email,
                promo_code: dataAccountRequest.promo_code,
            };
            let response = await AuthAPI.registerRequest(dataForm);
            if (response.status === 200) {
                if (response.data.status) {
                    setApiStatus(HandlerAPIMessage(3, response.data.message));
                    setSubmitFormStatus(response.data.status);
                } else setApiStatus(HandlerAPIMessage(5, response.data.message));
            } else setApiStatus(HandlerAPIMessage(5, 'Impossibile procedere, non c\'è nessuna connessione ad internet'));
        }
        else {
            if (validateName !== true) setApiStatus(HandlerAPIMessage(5, validateName));
            else if (validateEmail !== true) setApiStatus(HandlerAPIMessage(5, validateEmail));
        }
        setOnSubmitForm(false);
    }

    return (
        <section className={"py-0"}>
            <div className={"container-fluid"}>
                <div className={"g-0 min-vh-100 flex-center row"}>
                    <div className={"py-3 position-relative col-xxl-5 col-lg-8"}>
                        <img className={"bg-auth-circle-shape"} src={BG_SHAPE_01} alt="" width="450"/>
                        <img className={"bg-auth-circle-shape-2"} src={BG_SHAPE_02} alt="" width="250"/>
                        <motion.div className={"overflow-hidden z-index-1 card"}
                                    animate={{opacity: 1}}
                                    transition={{duration: 1.2}}
                                    initial={{opacity: 0}}>
                            <div className={"p-0 card-body"}>
                                <div className={"h-100 g-0 row"}>
                                    <div className={"text-white text-center " + (submitFormStatus ? 'bg-progress-gradient col-md-12' : 'bg-progress-gradient-alternative col-md-5')}>
                                        <div className="position-relative p-2 pt-md-5 pb-md-7">
                                            <div className="z-index-1 position-relative light">
                                                <AppBrandLogo type={"large"}/>
                                                {
                                                    (!submitFormStatus)
                                                        ? <p className="opacity-75 text-white mb-0 d-none d-sm-block">Risparmia tempo, riduci i costi di gestione e incrementa le vendite con <strong>gloowe</strong>. Crea il tuo menu digitale completo, semplice e intuitivo!</p>
                                                        :
                                                        <>
                                                            <h3 className="fw-semi-bold text-white">Perfetto!</h3>
                                                            <p className="opacity-75 text-white mb-0 px-md-5">Ti abbiamo inviato una email all'indirizzo che hai scritto, ora sei in lista per utilizzare al più presto <strong>gloowe</strong>. Ti invieremo una email al più presto con le istruzioni per creare il tuo profilo !</p>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-0 mb-4 mt-md-4 mb-md-5 light">
                                            <p className={"text-white " + (submitFormStatus ? 'd-none' : '')}>
                                                <strong>Hai un account?</strong><br/>
                                                <AppButton type={"link"} destination={"/login"} class={"text-white text-decoration-underline"}>Accedi qui</AppButton>
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        (!submitFormStatus)
                                            ?
                                            <div className={"d-flex justify-content-center align-items-center col-md-7"}>
                                                <div className={"p-4 p-md-5 flex-grow-1"}>
                                                    <h3>Iscriviti alla lista</h3>
                                                    <form onSubmit={formSubmit}>
                                                        <div className={"row"}>
                                                            <div className={"col-sm-12 col-md-12 mb-3"}>
                                                                <AppInput
                                                                    type={"input"}
                                                                    structure={"field-vertical"}
                                                                    labelFor={"name"} content={"Il tuo nome completo (*)"}
                                                                    class={"is-medium"}
                                                                    changeEvent={(e) => handleData(e)}
                                                                    placeHolder={"..."}/>
                                                            </div>
                                                            <div className={"col-sm-12 col-md-12 mb-3"}>
                                                                <AppInput
                                                                    type={"input"}
                                                                    structure={"field-vertical"}
                                                                    labelFor={"email"} content={"La tua email (*)"}
                                                                    class={"is-medium"}
                                                                    changeEvent={(e) => handleData(e)}
                                                                    placeHolder={"La tua email"}/>
                                                            </div>
                                                            <div className={"col-sm-12 col-md-12 mb-3"}>
                                                                <AppInput
                                                                    type={"input"}
                                                                    structure={"field-vertical"}
                                                                    labelFor={"msisdn"} content={"Il tuo numero di telefono"}
                                                                    class={"is-medium"}
                                                                    changeEvent={(e) => handleData(e)}
                                                                    placeHolder={"..."}/>
                                                            </div>
                                                            <div className={"col-sm-12 col-md-12 mb-3"}>
                                                                <AppInput
                                                                    type={"input"}
                                                                    structure={"field-vertical"}
                                                                    labelFor={"promo_code"} content={"Codice invito"}
                                                                    class={"is-medium"}
                                                                    changeEvent={(e) => handleData(e)}
                                                                    placeHolder={"..."}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <AppButton type={"submit"} class={"btn-primary w-100 " + (onSubmitForm ? 'is-loading' : '') + " is-medium"}>
                                                                {!onSubmitForm ? "Invia richiesta" : 'Invio richiesta ...'}
                                                            </AppButton>
                                                        </div>
                                                        <div className="justify-content-between align-items-center row">
                                                            <div className={"col-12 mt-3"}>
                                                                <MessageBanner type={"linear"} is_fixed={false}
                                                                               has_closed={false}
                                                                               code={apiStatus.class}
                                                                               text={apiStatus.textMessage}/>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RegisterRequest;