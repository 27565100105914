import React, {useState} from 'react';
import "./login.css";
import {motion} from 'framer-motion';
import AppButton from "../../utils/AppButton";
import AppInput from "../../utils/AppInput";
import AppBrandLogo from "../../utils/AppBrandLogo";
import MessageBanner from "../../utils/MessageBanner";
import BG_SHAPE_01 from "../../assets/images/bg-shape.001.png";
import BG_SHAPE_02 from "../../assets/images/bg-shape.001.png";
import HandlerAPIMessage from "../../constants/handle-api-message";
import InputValidation from "../../constants/input-validation";
import {AuthAPI} from "../../apis/AuthAPI";
import {SVG_NEXT, SVG_PREVIUS} from "../../utils/AppSvg";
import {Link} from "react-router-dom";

const RecoveryHead = ({phase}) => (
    <div className="justify-content-center nav card-header-undefined">
        {
            {
                1: <><h3>Hai dimenticato la tua password?</h3><p>Inserisci la tua email e ti invieremo un codice per il ripristino delle credenziali.</p></>,
                2: <><h3>Hai guardato la tua email?</h3><p>Inserisci il codice che ti abbiamo inviato via email.</p></>,
                3: <><h3>Scegli la nuova password!</h3><p>Ci siamo quasi, ora scegli la tua nuova password per poter accedere nuovamente al tuo account.</p></>,
                4: <><h3>Complimenti, credenziali ripristinate!</h3><p>Ora puoi accedere al tuo account, ti abbiamo anche inviato una email per confermare il ripristino delle tue credenziali.</p></>
            }[phase]
        }
    </div>
);

const Recovery = () => {
    const [stepRecovery, setStepRecovery] = useState(1);
    const [apiStatus, setApiStatus] = useState({})
    const [onSubmitForm, setOnSubmitForm] = useState(false)
    const [dataRecovery, setDataRecovery] = useState({
        recovery_email: '',
        recovery_code: '',
        recovery_password: '',
        recovery_password_confirm: '',
    });

    function handleData(e) {
        let currentName = (e.currentTarget.tagName === 'DIV') ? e.currentTarget.dataset.name : e.target.name;
        let currentValue = (e.currentTarget.tagName === 'DIV') ? e.currentTarget.dataset.value : e.target.value;

        switch (currentName) {
            case 'recovery_email':
                setDataRecovery({...dataRecovery, recovery_email: currentValue});
                break;
            case 'recovery_code':
                setDataRecovery({...dataRecovery, recovery_code: currentValue});
                break;
            case 'recovery_password':
                setDataRecovery({...dataRecovery, recovery_password: currentValue});
                break;
            case 'recovery_password_confirm':
                setDataRecovery({...dataRecovery, recovery_password_confirm: currentValue});
                break;
            default:
                console.info('No type for ' + currentName);
                break;
        }
    }

    async function toggleStep(state) {
        let current = stepRecovery;
        if (state === 'next') current = stepRecovery + 1;
        else if (state === 'prev') current = stepRecovery - 1;

        let canToggle;
        if (state === 'next') {
            setOnSubmitForm(true);
            switch (current) {
                case 2:
                    document.getElementById('recovery_email').classList.remove('is-invalid');

                    canToggle = InputValidation(dataRecovery.recovery_email, 'email');
                    if (true === canToggle) {
                        let responseRecoveryCredential = await AuthAPI.recoveryCredential('check-email', dataRecovery);
                        if (responseRecoveryCredential.status === 200 && (!responseRecoveryCredential.data.status)) canToggle = responseRecoveryCredential.data.message;
                        if (true !== canToggle) document.getElementById('recovery_email').classList.add('is-invalid');
                    }
                    break;
                case 3:
                    document.getElementById('recovery_code').classList.remove('is-invalid');
                    canToggle = InputValidation(dataRecovery.recovery_code, 'text');
                    if (true === canToggle) {
                        let responseRecoveryCredential = await AuthAPI.recoveryCredential('check-code', dataRecovery);
                        if (responseRecoveryCredential.status === 200 && (!responseRecoveryCredential.data.status)) canToggle = responseRecoveryCredential.data.message;
                        if (true !== canToggle) document.getElementById('recovery_code').classList.add('is-invalid');
                    }
                    break;
                case 4:
                    document.getElementById('recovery_password').classList.remove('is-invalid');
                    document.getElementById('recovery_password_confirm').classList.remove('is-invalid');

                    canToggle = InputValidation(dataRecovery.recovery_password, 'password');
                    if (true !== canToggle) document.getElementById('recovery_password').classList.add('is-invalid');

                    if (true === canToggle) {
                        canToggle = InputValidation([dataRecovery.recovery_password, dataRecovery.recovery_password_confirm], 'password_confirm');
                        if (true !== canToggle) {
                            document.getElementById('recovery_password').classList.add('is-invalid');
                            document.getElementById('recovery_password_confirm').classList.add('is-invalid');
                        }
                    }

                    if (true === canToggle) {
                        console.log(dataRecovery);
                        let responseSignup= await AuthAPI.recoveryCredential('reset-credential', dataRecovery);
                        if (responseSignup.status === 200 && (!responseSignup.data.status)) canToggle = responseSignup.data.message;
                    }

                    break;
                default:
                    canToggle = false;
                    break;
            }
        } else canToggle = true;

        if (canToggle === true) {
            setApiStatus({});
            setStepRecovery(current);
        } else setApiStatus(HandlerAPIMessage(5, canToggle));
        setOnSubmitForm(false);
    }

    return (
        <section className={"py-0"}>
            <div className={"container-fluid"}>
                <div className={"g-0 min-vh-100 flex-center row"}>
                    <div className={"py-3 position-relative col-xxl-5 col-lg-8"}>
                        <img className={"bg-auth-circle-shape"} src={BG_SHAPE_01} alt="" width="250"/>
                        <img className={"bg-auth-circle-shape-2"} src={BG_SHAPE_02} alt="" width="150"/>
                        <motion.div className={"overflow-hidden z-index-1 card"}
                                    animate={{opacity: 1}}
                                    transition={{duration: 1.2}}
                                    initial={{opacity: 0}}>
                            <div className={"p-0 card-body"}>
                                <div className={"h-100 g-0 row"}>
                                    <div className={"text-white text-center bg-card-gradient col-md-5"}>
                                        <div className="position-relative p-2 pt-md-5 pb-md-7">
                                            <div className="z-index-1 position-relative light">
                                                <AppBrandLogo type={"large"}/>
                                                <p className="opacity-75 text-white mb-0">
                                                    Con la potenza di <strong>gloowe</strong>, ora puoi concentrarti
                                                    sui gestione e incrementa le vendite.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-0 mb-4 mt-md-4 mb-md-5 light">
                                            <p className="text-white">
                                                <strong>Non hai un account?</strong><br/>
                                                <AppButton type={"link"} destination={"/register"} class={"text-white text-decoration-underline"}>Registrati qui</AppButton>
                                            </p>
                                        </div>
                                    </div>
                                    <div className={"d-flex justify-content-center align-items-center col-md-7"}>
                                        <div className={"p-4 p-md-5 flex-grow-1"}>
                                            <RecoveryHead phase={stepRecovery} />
                                            <div className="justify-content-between align-items-center row">
                                                {
                                                    {
                                                        1:
                                                            <>
                                                                <div className={"col-12 mb-3"}>
                                                                    <AppInput type={"input"} structure={"no-field"} labelFor={"recovery_email"}
                                                                              class={"is-medium"} changeEvent={(e) => handleData(e)}
                                                                              placeHolder={"Email di accesso"}
                                                                              value={dataRecovery.recovery_email}/>
                                                                </div>
                                                                <div className={"col-12"}>
                                                                    <button type="button" onClick={() => toggleStep('next')}
                                                                            className={"ms-auto px-5 btn btn-primary w-100"}
                                                                            disabled={onSubmitForm}>Prosegui<SVG_NEXT/>
                                                                    </button>
                                                                </div>
                                                            </>,
                                                        2:
                                                            <>
                                                                <div className={"col-12 mb-3"}>
                                                                    <AppInput type={"input"} structure={"no-field"} labelFor={"recovery_code"}
                                                                              class={"is-medium"} changeEvent={(e) => handleData(e)}
                                                                              placeHolder={"Codice ricevuto via email"}
                                                                              value={dataRecovery.recovery_code}/>
                                                                </div>
                                                                <div className={"col-12 mb-3"}>
                                                                    <button type="button" onClick={() => toggleStep('next')}
                                                                            className={"ms-auto px-5 btn btn-primary w-100"}
                                                                            disabled={onSubmitForm}>Prosegui<SVG_NEXT/>
                                                                    </button>
                                                                </div>
                                                                <div className={"col-12"}>
                                                                    <button type="button" onClick={() => toggleStep('prev')}
                                                                            className={"px-0 btn btn-link w-100"}>
                                                                        <SVG_PREVIUS/>Torna indietro
                                                                    </button>
                                                                </div>
                                                            </>
                                                        ,
                                                        3:
                                                            <>
                                                                <div className={"col-12 mb-3"}>
                                                                    <AppInput type={"password"} structure={"no-field"} labelFor={"recovery_password"}
                                                                              class={"is-medium"} changeEvent={(e) => handleData(e)}
                                                                              placeHolder={"Sceglia la tua nuova password"}
                                                                              value={dataRecovery.recovery_password}/>
                                                                </div>
                                                                <div className={"col-12 mb-3"}>
                                                                    <AppInput type={"password"} structure={"no-field"} labelFor={"recovery_password_confirm"}
                                                                              class={"is-medium"} changeEvent={(e) => handleData(e)}
                                                                              placeHolder={"Conferma la nuova password"}
                                                                              value={dataRecovery.recovery_password_confirm}/>
                                                                </div>
                                                                <div className={"col-12"}>
                                                                    <button type="button" onClick={() => toggleStep('next')}
                                                                            className={"ms-auto px-5 btn btn-success w-100"}
                                                                            disabled={onSubmitForm}>Conferma</button>
                                                                </div>
                                                            </>,
                                                        4:
                                                            <>
                                                                <div className={"col-12"}>
                                                                    <Link to={"/login"} className={"ms-auto px-5 btn btn-primary w-100"}>Accedi</Link>
                                                                </div>
                                                            </>
                                                    }[stepRecovery]
                                                }
                                                <div className={"col-12 pt-2"}>
                                                    <MessageBanner type={"linear"} is_fixed={false} has_closed={false}
                                                                   code={apiStatus.class} text={apiStatus.textMessage}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Recovery;
