import {useGlobalContext} from "./application-context";
import {AuthAPI} from "../apis/AuthAPI";

function HandlerAPILogout() {
    const global = useGlobalContext();

    async function logoutAuth(event) {
        event.preventDefault();
        let responseLogout = await AuthAPI.logout(global.token);
        if (responseLogout.status === 200) {
            if (responseLogout.data.status) {
                localStorage.removeItem("token");
                localStorage.removeItem("store_id_selected");
                window.location.reload();
            } else console.error('AuthAPI Logout Status False');
        } else console.error('AuthAPI Logout Error');
    }

    return {logoutAuth};
}

export default HandlerAPILogout;
