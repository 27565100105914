import React from "react";
import AppButton from "../utils/AppButton";
import {SVG_TRASH} from "../utils/AppSvg";

const HandlerRemoveFile = ({handlerRefreshData, type}) => {
    return (
        <div className={"position-absolute d-flex"} style={{bottom: '15px', right: '15px'}}>
            <div>
                <AppButton type={"button"}
                           class={"btn-sm btn-warning is-block is-fullwidth"}
                           action={() => handlerRefreshData(type)}><SVG_TRASH/></AppButton>
            </div>
        </div>
    );
}

export default HandlerRemoveFile;