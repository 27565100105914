import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {AuthAPI} from "../../apis/AuthAPI";
import {useGlobalContext} from "../../constants/application-context";
import AppPlaceholder from "../../utils/AppPlaceholder";
import AppBreadcrumb from "../../utils/AppBreadcrumb";
import {SVG_EDIT, SVG_EXPORT} from "../../utils/AppSvg";
import Modal from "../modal";
import ModalContainer from "../modal_container";
import FormDomain from "../form/domain";
import HandleStatusArchive from "../../constants/handle-status-archive";

const RenderDomain = ({data}) => {
    const [selectedModal, setSelectedModal] = useState(null);

    let item = data.single ?? null;
    //let list_menu = data.list_menu ?? null;

    return (
        <>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className="mb-3 card">
                        <div className="position-relative card-body">
                            <div className="flex-center row">
                                <div className="col">
                                    <h5 className="mb-0">{item.name}</h5>
                                    {item.url && <p className="fs--1 mt-1 mb-1"><strong className="text-primary">{item.url}</strong></p>}
                                    <p className="fs--1 mt-3 mb-1">Creato il {item.created_at}</p>
                                    <p className="fs--1 mt-1 mb-1">Stato: {item.is_active ? <strong className="text-success">Attivo</strong> : <strong className="text-danger">Archiviato</strong>}</p>
                                </div>
                                <div className="col-auto">
                                    <div className="d-flex">
                                        <div className="px-2 px-md-3 col-auto">
                                            <button type="button" className="px-4 px-sm-5 btn btn-falcon-default btn-sm" onClick={() => setSelectedModal(data)}>
                                                <SVG_EDIT/>
                                                <span className="d-none d-xl-inline-block ms-1">Modifica</span>
                                            </button>
                                        </div>
                                        <div className="px-2 px-md-3 col-auto">
                                            <HandleStatusArchive isActive={(item.is_active === 1)}
                                                                 typeHandle={'menu-domain'}
                                                                 refId={item.id} item={item}/>
                                        </div>
                                        {
                                            item.url &&
                                                <div className="pe-2 px-md-3 col-auto">
                                                    <a href={"//" + item.url} target={"_blank"} rel="noreferrer"
                                                       className="px-4 px-sm-5 btn btn-info btn-sm">
                                                        <SVG_EXPORT />
                                                        <span className="d-none d-xl-inline-block ms-1">Visita</span>
                                                    </a>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-12"}>
                    <div className="mb-3 card">
                        <div className="card-body">
                            <div className="row">
                                <div className="mb-4 col-sm-12 col-md-8">
                                    <h5 className="mb-3 fs-0">Descrizione</h5>
                                    <p className="mb-1 fs--1">{item.description ?? 'Nessuna descrizione'}</p>
                                </div>
                                <div className="mb-4 col-sm-12 col-md-4">
                                    <h5 className="mb-3 fs-0">Configurazione Dominio</h5>
                                    {
                                        item.token
                                            ?
                                            <div className="mb-3 input-group">
                                                <span className="input-group-text mb-0 fs--1 fw-bold">Token</span>
                                                <input className="form-control fs--1 fw-bold" disabled value={item.token}/>
                                            </div>
                                            :
                                            <p className="mb-1 fs--1 text-warning">Nessuna configurazione avviata per il tuo menù digitale. Modifica le impostazioni per poter aver anche te un tuo menù digitale.</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalContainer>
                {
                    selectedModal &&
                    <Modal handleClose={() => setSelectedModal(null)}
                           item={{name: 'domain', title: item.name, action: 'edit-domain'}}>
                        <div className={"modal-container card overflow-hidden"}>
                            <div className="card-header">
                                <h4 className="font-alt fs-1 modal-title color-brand-dark col-12 text-center">Ordine Confermato</h4>
                            </div>
                            <FormDomain id={item.id} handleClose={() => setSelectedModal(null)}/>
                        </div>
                    </Modal>
                }
            </ModalContainer>
        </>
    );
}

const SingleDomain = () => {
    const {id} = useParams();
    const global = useGlobalContext();
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let data = {menu_domain_id: id};
            let responseData = await AuthAPI.menuDomainItem(global.token, global.tokenStore, data);
            // convert data to json
            if (responseData.status === 200) setData(responseData.data);
        }

        fetchData().then(() => setLoadingData(false));
    }, [global.token, global.tokenStore, id, loadingData]);

    return (
        <>
            <div className={"row mt-3"}>
                <AppBreadcrumb>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/dashboard"}>Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to={"/dashboard/menu-domain"} role="button" tabIndex="0">Lista
                            Menù Domini</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Dominio</li>
                    </ol>
                </AppBreadcrumb>
                <div className={"col-8"}>
                    {
                        loadingData
                            ?
                            <div className={"row"}>
                                <div className={"col-12 mb-3"}><AppPlaceholder rows={3}/></div>
                                <div className={"col-12"}><AppPlaceholder rows={7}/></div>
                            </div>
                            : <RenderDomain data={data.context}/>
                    }
                </div>
            </div>
        </>
    );
}

export default SingleDomain;