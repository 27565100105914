import {useRef} from "react";

const useGooglePlaceAutoComplete = () => {
    const autoCompleteRef = useRef();
    const options = {
        componentRestrictions: {country: "it"},
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["geocode"]
    };

    const initAutoComplete = async (input, callback) => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(input, options);
        autoCompleteRef.current.addListener("place_changed", callback);

        return autoCompleteRef;
    };

    const getFullAddress = async (autoComplete) => {
        const place = autoComplete.current.getPlace();
        let full_address = "", street_number, address, localityShort, localityLong, adminArea1Short, adminArea1Long, adminArea2Short,
            adminArea2Long, adminArea3Short, adminArea3Long, countryShort, countryLong, postalCode = "";

        try{
            if (place === null || undefined) {
                console.error('API PLACE IS UNDEFINED')
                throw '';
            }

            // Get each component of the address from the place details,
            for (const component of place.address_components) {

                const componentType = component.types[0];

                if (componentType === "street_number") {
                    street_number = component.long_name;
                }
                if (componentType === "route") {
                    address = component.long_name;
                }
                if (componentType === "locality") {
                    localityShort = component.short_name;
                    localityLong = component.long_name;
                }
                if (componentType === "administrative_area_level_1") {
                    adminArea1Short = component.short_name;
                    adminArea1Long = component.long_name;
                }
                if (componentType === "administrative_area_level_2") {
                    adminArea2Short = component.short_name;
                    adminArea2Long = component.long_name;
                }
                if (componentType === "administrative_area_level_3") {
                    adminArea3Short = component.short_name;
                    adminArea3Long = component.long_name;
                }
                if (componentType === "postal_code") {
                    postalCode = component.long_name;
                }
                if (componentType === "postal_code_suffix") {
                    postalCode = `${postalCode}-${component.long_name}`;
                }
                if (componentType === "country") {
                    countryShort = component.short_name;
                    countryLong = component.long_name;
                }
            }

            if ((localityShort === null || undefined) || street_number === null || undefined) throw '';
            if (localityShort.length > 0) full_address = address;
            if (street_number.length > 0) full_address += (full_address.length === 0 ? '' : ', ') + street_number;

            let dataObj = {
                "full_address": full_address,
                "street_number": street_number,
                "route": address,
                "locality": {short: localityShort, long: localityLong},
                "administrative_area_level_3": {short: adminArea3Short, long: adminArea3Long},
                "administrative_area_level_2": {short: adminArea2Short, long: adminArea2Long},
                "administrative_area_level_1": {short: adminArea1Short, long: adminArea1Long},
                "postal_code": postalCode,
                "postal_code_suffix": countryShort,
                "country": countryLong,
                "geometry": place.geometry
            };

            return {
                status: true,
                context: dataObj
            }
        } catch (e) {
            return {
                status: false,
                message: e.message,
                context: null
            }
        }
    };

    return {initAutoComplete, getFullAddress};
};

export default useGooglePlaceAutoComplete;