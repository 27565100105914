import React from "react";
import {Link} from "react-router-dom";
import GlooweLogoSmall from "../assets/images/gloowe-logo-01-sm.png";
import GlooweLogoNormal from "../assets/images/gloowe-logo-02.png";
import GlooweLogoNormalSmall from "../assets/images/gloowe-logo-02-sm.png";
import GlooweLogoFullColor from "../assets/images/gloowe-logo-01.png";
import {useGlobalContext} from "../constants/application-context";

const AppBrandLogo = ({type = 'normal'}) => {
    const global = useGlobalContext();
    let html_BrandLogo = '';

    // eslint-disable-next-line default-case
    switch (type) {
        case 'small':
            html_BrandLogo = <img id="navbar-logo" alt="gloowe" role="presentation" className={"logo-small"} src={GlooweLogoSmall}/>;
            break;
        case 'small-horizontal':
            html_BrandLogo = <img id="navbar-logo" alt="gloowe" role="presentation" className={"logo-small"} src={GlooweLogoFullColor} style={{width:"30%"}}/>;
            break;
        case 'normal':
            html_BrandLogo = <Link className={"brand"} to={"/"}>
                <div className={"brand-icon"}>
                    <img id="navbar-logo" alt="gloowe" role="presentation" src={GlooweLogoSmall}/>
                </div>
                {!global.token ? (<div className="brand-content">
                    <div className={"brand-title ml-1"}>gloowe</div>
                    <div className={"brand-subtitle ml-1"}>un metaverso di quartieri</div>
                </div>) : <></>}
            </Link>;
            break;
        case 'normal-horizontal':
            html_BrandLogo =
                <Link className={"brand"} to="/">
                    <div className={"brand-icon"}>
                        <img id="navbar-logo" alt="gloowe" role="presentation"
                             src={((!global.token) ? GlooweLogoNormalSmall : GlooweLogoNormal)}/>
                    </div>
                </Link>;
            break;
        case 'large':
            html_BrandLogo = <Link className={"brand"} to="/">
                <div className={"brand-icon"}>
                    <img id="navbar-logo" alt="gloowe" role="presentation" className={"logo-large"} src={GlooweLogoNormal}/>
                </div>
            </Link>;
            break;
    }

    return html_BrandLogo;
};

export default AppBrandLogo;
