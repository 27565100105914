import React, {useState} from "react";
import PropTypes from "prop-types";
import Modal from "../components/modal";
import ModalContainer from "../components/modal_container";
import AppInput from "../utils/AppInput";
import HandlerAPIMessage from "./handle-api-message";
import InputValidation from "./input-validation";
import {AuthAPI} from "../apis/AuthAPI";
import AppButton from "../utils/AppButton";

const HandlerStatusArchive = ({type = 'button', isArchive, typeHandle, refId, item}) => {
    let title;
    let currentName = (item && item.name ? item.name : 'gloowe');

    //const global = useGlobalContext();
    const [apiStatus, setApiStatus] = useState({})
    const [selectedModal, setSelectedModal] = useState(null);
    const [onSubmitForm, setOnSubmitForm] = useState(false)
    const [dataStatusArchive, setDataStatusArchive] = useState({
        ref_id: refId,
        type: typeHandle,
        action: isArchive ? 'restore' : 'archive',
        phase_original: currentName,
        phase: '',
    });

    switch (typeHandle) {
        case 'menu':
            title = "Vuoi davvero eliminare il menù " + currentName + "?";
            break;
        case 'menu-domain':
            title = "Vuoi davvero eliminare il dominio " + currentName + "?";
            break;
        case 'menu-category':
            title = "Vuoi davvero eliminare la categoria " + currentName + "?";
            break;
        case 'menu-consumable':
            title = "Vuoi davvero eliminare la portata " + currentName + "?";
            break;
        default:
            title = null;
            break;
    }

    const formSubmitStatusArchive = async e => {
        setOnSubmitForm(true);
        setApiStatus(HandlerAPIMessage(undefined, null));
        e.preventDefault();
        let token = localStorage.getItem("token") ?? null;
        let tokenStore = localStorage.getItem("store_id_selected") ?? null;
        let validatePhase = InputValidation(dataStatusArchive.phase, 'text');
        if (validatePhase === true) {
            let dataForm = {
                ref_id: dataStatusArchive.ref_id,
                type: dataStatusArchive.type,
                action: dataStatusArchive.action,
                phase_original: dataStatusArchive.phase_original,
                phase: dataStatusArchive.phase,
            };
            let response = await AuthAPI.statusArchive(token, tokenStore, dataForm);
            if (response.status === 200) {
                if (response.data.status) {
                    let responseMessage = response.data.message !== null ? response.data.message : null;
                    setApiStatus(HandlerAPIMessage(3, responseMessage));
                } else setApiStatus(HandlerAPIMessage(5, response.data.message));
            } else setApiStatus(HandlerAPIMessage(5, 'Impossibile procedere, non c\'è nessuna connessione ad internet'));
        } else {
            if (validatePhase !== true) setApiStatus(HandlerAPIMessage(5, validatePhase));
        }
        setOnSubmitForm(false);
    }

    return (
        <>
            <button type="button" onClick={() => setSelectedModal(true)}
                    className={(type === 'button' ? "px-2 px-sm-3 btn btn-sm " + (isArchive ? "btn-outline-success" : "btn-outline-danger") : (isArchive ? "text-success" : "text-danger") + " dropdown-item")}>
                {type === 'button' ? <span className="d-none d-xl-inline-block ms-1">{isArchive ? 'Ripristina' : 'Archivia'}</span> : (isArchive ? 'Ripristina' : 'Archivia')}
            </button>
            <ModalContainer>
                {
                    selectedModal &&
                    <Modal handleClose={() => setSelectedModal(null)} dimension={'modal-xs'} modalCss={isArchive ? 'bg-white' : 'alert alert-danger'}>
                        <div className={"modal-container card overflow-hidden"}>
                            {title && <div className="alert-heading h4 text-center">{title}</div>}
                            {
                                {
                                    'menu': <p>Archiviando {currentName} verrà eliminato <u>unicamente</u> il menù <u>e non tutte le categorie e portate a cui è associato</u>.</p>,
                                    'menu-domain': <p>Archiviando {currentName} verrà eliminato <u>unicamente</u> il dominio <u>e non tutti menù a cui è associato</u>.</p>,
                                    'menu-category': <p>Archiviando {currentName} verrà eliminato <u>unicamente</u> la categoria <u>e non tutte le voci a cui è associata</u>.</p>,
                                    'menu-consumable': <p>Archiviando {currentName} verrà eliminato <u>unicamente</u> la portata <u>e non le categorie a cui è associato</u>.</p>,
                                }[typeHandle]
                            }
                            <div className={"fs-sm--1"}>
                                <strong>Importante</strong>
                                <p>Se cambi idea, hai a disposizione 14 giorni per annullare l'operazione. Riceverai via e-mail le istruzioni per ripristinare il team, oppure puoi consultare il nostro Centro Assistenza.</p>
                            </div>
                            <form onSubmit={formSubmitStatusArchive}>
                                <div className={"col-12"}>
                                    <strong className={"fs-md--1"}>Inserisci la parola "{currentName}" per confermare
                                        l'eliminazione.</strong>
                                    <AppInput type={"input"} structure={"field-vertical"} labelFor={"phase"}
                                              placeHolder={"Scrivi la parola suggerita"} class={"is-medium mt-1"}
                                              changeEvent={(e) => setDataStatusArchive({
                                                  ...dataStatusArchive,
                                                  phase: e.target.value
                                              })}/>
                                </div>
                                <div className={"col-12 text-center"}>
                                    <AppButton type={"submit"}
                                               class={(isArchive ? 'btn-primary' : 'btn-danger') + " " + (onSubmitForm ? 'is-loading' : '') + " is-block is-fullwidth is-medium mt-4"}
                                               disabled={(dataStatusArchive.phase.length === 0)}>{isArchive ? 'Ripristina' : 'Archivia'}</AppButton>
                                </div>
                            </form>
                        </div>
                    </Modal>
                }
            </ModalContainer>
        </>
    );
}

HandlerStatusArchive.propTypes = {
    isArchive: PropTypes.bool.isRequired,
    typeHandle: PropTypes.string.isRequired,
    refId: PropTypes.number.isRequired,
};

export default HandlerStatusArchive;
