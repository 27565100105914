import React, {useEffect, useState} from 'react';
import {Document, Page, PDFViewer, StyleSheet, Text, View, Image, Font} from '@react-pdf/renderer';
import {AuthAPI} from "../../apis/AuthAPI";
import {useParams} from "react-router-dom";
import TemplateMenuBasic from "../../assets/template_menu/menu_basic.png";

// Create styles
// Register Font
Font.register({
    family: "Roboto", fonts: [
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf' }, // font-style: normal, font-weight: normal
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', fontStyle: 'italic' },
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontStyle: 'normal', fontWeight: 400},
    //src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
]});

const styles = StyleSheet.create({
    pageBackground: {position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px', marginHorizontal: 'auto', textAlign: "center", justifyContent: 'center'},
    page: {padding: 35},
    row: {flex: 1, flexDirection: 'row', flexGrow: 1},
    pageNumbers: {position: 'absolute', bottom: 20, left: 0, right: 0, textAlign: 'center'},
    containerMenu: {textAlign: 'center', marginTop: 25, marginBottom: 20},
    menuTitle: {fontFamily: 'Roboto', fontSize: 24, fontWeight: 'extrabold', padding: 0, marginBottom: 25, marginTop: 25},
    containerCategory: {width: '100%', padding: 5, marginBottom: 15, textAlign: 'center'},
    categoryTitle: {fontFamily: 'Roboto', fontSize: 18, fontWeight: 'bold', padding: 0, marginBottom: 10},
    containerItem: {fontFamily: 'Roboto', textAlign: 'center', paddingTop:0, paddingBottom: 5, paddingLeft: 35, paddingRight: 35},
    itemTitle: {fontFamily: 'Roboto', fontSize: 14, fontWeight: 'semibold', padding: 0, marginBottom: 5, color: '#3c3c3c'},
    itemDescription: {fontFamily: 'Roboto', fontSize: 10, width: '75%', margin: '0 auto', fontStyle: 'italic', padding: 0, color: '#8d8d8d', lineHeight: '1.25px'},
    itemPrice: {fontFamily: 'Roboto', fontSize: 14, fontWeight: 'semibold', padding: 0, marginLeft: 15, marginRight: 5, marginBottom: 5, color: '#3c3c3c'},
});

const ViewPDF = () => {
    const {id} = useParams();
    const [isDataComplete, setIsDataComplete] = useState(false);
    const [dataStore, setDataStore] = useState({});
    const [dataStoreMeta, setDataStoreMeta] = useState({});
    const [dataMenuDomain, setDataMenuDomain] = useState({});
    const [dataMenu, setDataMenu] = useState({});
    const [dataMenuCategory, setDataMenuCategory] = useState({});
    const [dataMenuConsumable, setDataMenuConsumable] = useState({});

    useEffect(() => {
        async function fetchData() {
            let responseData = await AuthAPI.menuInfo({menu_id: parseInt(id)});
            return (responseData.status === 200) ? responseData.data : null;
        }

        fetchData().then((response) => {
            try {
                let context = response.status ? response.context : null;
                setDataStore((context ? context.store : null));
                setDataStoreMeta((context ? context.store_meta : null));
                setDataMenuDomain((context ? context.menu_domain : null));
                setDataMenu((context ? (context.menu ?? null) : null));
                setDataMenuCategory((context ? (context.list_category ?? null) : null));
                setDataMenuConsumable((context ? (context.list_consumable ?? null) : null));

                setIsDataComplete(true);
            } catch (e) {
                setIsDataComplete(false);
            }
        });
    }, [id]);

    if (isDataComplete) {
        return (
            <PDFViewer width={"100%"} height={window.screen.height}>
                <Document title={"test"}>
                    <Page style={styles.page} size="A4" wrap={true}>
                        <Image src={TemplateMenuBasic} style={styles.pageBackground} />
                        <View style={styles.containerMenu}>
                            <Text style={styles.menuTitle}>{dataMenu.name}</Text>
                        </View>
                        {
                            dataMenuCategory && dataMenuCategory.map((item, key) => {
                                let currentSub = dataMenuConsumable.filter(i => i.menu_category_id === item.id);
                                return currentSub.length > 0 &&
                                    <ViewMenuCategory key={key.toString()} item={item} sub={currentSub}/>
                            })
                        }
                        {/*<Text style={styles.pageNumbers} render={({pageNumber, totalPages}) => (`${pageNumber} / ${totalPages}`)} fixed/>*/}
                    </Page>
                </Document>
            </PDFViewer>
        );
    } else {
        return <p>Caricando ...</p>;
    }
};

const ViewMenuCategory = ({item, sub}) => {
    return (
        <View style={styles.containerCategory}>
            <Text style={styles.categoryTitle}>{item.name}</Text>
            {
                sub && sub.map((itemSub, key) =>
                    <View key={key.toString()} style={styles.containerItem}>
                        <Text key={key.toString()} style={styles.itemTitle}>
                            {itemSub.name} <ViewMenuCategoryPrice item={itemSub}/>
                        </Text>
                        <Text key={key.toString()} style={styles.itemDescription}>{itemSub.description}</Text>
                    </View>
                )
            }
        </View>
    );
}

const ViewMenuCategoryPrice = ({item}) => {
    if (item.num_variations === 0) return <Text style={styles.itemPrice}>€ {item.price}</Text>;
    else {
        return (
            <>
                {
                    item.list_variation && item.list_variation.map((variation, key) =>
                        <View key={key.toString()} style={styles.containerItem}>
                            <Text style={styles.itemPrice}>{'€ ' + parseFloat(variation.price).toFixed(2)  + ' ' + variation.name + ' | '}</Text>
                        </View>
                    )
                }
            </>
        );
    }
}

export default ViewPDF;