import './index.css';
import React, {useEffect, useState} from "react";
import {useGlobalContext} from "../../constants/application-context";
import {AuthAPI} from "../../apis/AuthAPI";
import InputValidation from "../../constants/input-validation";
import HandlerAPIMessage from "../../constants/handle-api-message";
import AppInput from "../../utils/AppInput";
import AppButton from "../../utils/AppButton";
import MessageBanner from "../../utils/MessageBanner";
import Dropzone from "../dropzone";
import AppPlaceholder from "../../utils/AppPlaceholder";
import {getFileUrl} from "../../utils/Utils";

const LayoutListMenu = ({type, data, currentData = null, changeEvent}) => {
    if (data !== null && data.length > 0) {
        let jsonCurrentData = currentData !== null ? JSON.parse(currentData) : [];
        return (
            <>
                {
                    data.map((item, key) =>
                        <div key={key.toString()} data-name={type} data-value={item.id}
                             className={"d-flex align-items-center rounded-3 bg-light p-3 mb-2 border cursor-pointer " + ((jsonCurrentData.some(jsonItem => parseInt(item.id) === parseInt(jsonItem))) ? 'border-primary' : '')}
                             onClick={changeEvent}>
                            <h6 className="mb-0 fw-bold">{item.name}</h6>
                        </div>
                    )
                }
            </>
        );
    } else {
        return (<div className={"col-12"}><AppPlaceholder rows={2} classCSS={"card-no-box"} /></div>);
    }
}

const FormMenuCategory = (props) => {
    let id = props.id ?? null;
    const global = useGlobalContext();
    const [apiStatus, setApiStatus] = useState({})
    const [onSubmitForm, setOnSubmitForm] = useState(false)
    const [submitFormStatus, setSubmitFormStatus] = useState(false)
    const [isDataComplete, setIsDataComplete] = useState(false)
    const [preview, setPreview] = useState({})
    const [listMenu, setListMenu] = useState({})
    const [dataMenuCategory, setDataMenuCategory] = useState({
        name: '',
        description: '',
        parent_id: '',
        file_cover: '',
        list_menu_id: '',
    });

    useEffect(() => {
        async function fetchData() {
            let responseDataCategory, responseDataMenuList;
            if (id !== null) {
                responseDataCategory = await AuthAPI.menuCategoryItem(global.token, global.tokenStore, {menu_category_id: id});
                if (responseDataCategory.status === 200) {
                    let data_list_menu_id = [];
                    let data = responseDataCategory.data.status ? responseDataCategory.data.context.single : null;
                    let list_menu = responseDataCategory.data.status ? responseDataCategory.data.context.list_menu : null;
                    if (list_menu) list_menu.map(item => data_list_menu_id.push(item.id.toString()));

                    let image_menu_category = getFileUrl(data, 'url_menu_category_cover');
                    if (image_menu_category !== null) setPreview(image_menu_category);

                    setDataMenuCategory({
                        ...dataMenuCategory
                        , id: data.id
                        , name: data.name
                        , description: data.description
                        , parent_id: data.parent_id
                        , list_menu_id: ((data_list_menu_id.length > 0) ? JSON.stringify(data_list_menu_id) : '')
                    });
                }
            }
            responseDataMenuList = await AuthAPI.menuList(global.token, global.tokenStore);
            return (responseDataMenuList.status === 200) ? responseDataMenuList.data : null;
        }
        fetchData().then((responseContext) => {
            setIsDataComplete(responseContext !== null);
            if (responseContext !== null) {
                setListMenu(responseContext.status ? responseContext.context.list : null);
            }
        });
    }, []);

    function handleData(e) {
        //let currentType = e.target.type;
        let currentName = (e.currentTarget.tagName === 'DIV') ? e.currentTarget.dataset.name : e.target.name;
        let currentValue = (e.currentTarget.tagName === 'DIV') ? e.currentTarget.dataset.value : e.target.value;

        if (e.currentTarget.type === 'file') currentValue = e.target.files[0];

        if (e.currentTarget.tagName === 'DIV') {
            if (e.currentTarget.classList.contains('border-primary')) e.currentTarget.classList.remove('border-primary');
            else e.currentTarget.classList.add('border-primary');
        }

        switch (currentName) {
            case 'name':
                setDataMenuCategory({...dataMenuCategory, name: currentValue});
                break;
            case 'description':
                setDataMenuCategory({...dataMenuCategory, description: currentValue});
                break;
            case 'parent_id':
                setDataMenuCategory({...dataMenuCategory, parent_id: currentValue});
                break;
            case 'file_cover':
                setDataMenuCategory({...dataMenuCategory, file_cover: currentValue});
                // Generate Preview
                const objectUrl = URL.createObjectURL(currentValue);
                setPreview(objectUrl);
                return () => URL.revokeObjectURL(objectUrl);
                break;
            case 'list_menu_id':
                // Search on list and add or delete current value
                let currentList = (dataMenuCategory.list_menu_id.length === 0) ? [] : JSON.parse(dataMenuCategory.list_menu_id);
                let currentKey = currentList.indexOf(currentValue);
                if (currentKey === null || currentKey === -1) currentList.push(currentValue);
                else currentList.splice(currentKey, 1);

                currentValue = JSON.stringify(currentList);
                setDataMenuCategory({...dataMenuCategory, list_menu_id: currentValue});
                break;
            default:
                console.info('No type for ' + currentName);
                break;
        }
    }

    function handleRemoveDataFile(e) {
        setDataMenuCategory({...dataMenuCategory, file_cover: ''});
        setPreview({});
    }

    const formSubmit = async e => {
        setOnSubmitForm(true);
        e.preventDefault();
        let token = localStorage.getItem("token") ?? null;
        let tokenStore = localStorage.getItem("store_id_selected") ?? null;
        let validateName = InputValidation(dataMenuCategory.name, 'text');
        let validateListMenuId = InputValidation(dataMenuCategory.list_menu_id, 'array');
        if (validateName === true && validateListMenuId === true) {
            let dataForm = {
                menu_category_id: dataMenuCategory.id ?? null,
                name: dataMenuCategory.name,
                description: dataMenuCategory.description,
                parent_id: dataMenuCategory.parent_id,
                file: dataMenuCategory.file_cover,
                list_menu_id: dataMenuCategory.list_menu_id,
            };
            let response = await AuthAPI.menuCategorySave(token, tokenStore, dataForm);
            if (response.status === 200) {
                if (response.data.status) {
                    global.setDataUpdate(!global.dataUpdate);
                    let responseContext = response.data.context !== null ? response.data.context : null;
                    let responseMessage = response.data.message !== null ? response.data.message : null;
                    if (responseContext === null || responseContext.length === 0) setApiStatus(HandlerAPIMessage(5, 'Errore imprevisto'));
                    else setApiStatus(HandlerAPIMessage(3, responseMessage));
                    setSubmitFormStatus(response.data.status);
                } else setApiStatus(HandlerAPIMessage(5, response.data.message));
            } else setApiStatus(HandlerAPIMessage(5, 'Impossibile procedere, non c\'è nessuna connessione ad internet'));
        } else {
            if (validateName !== true) setApiStatus(HandlerAPIMessage(5, validateName));
            else if (validateListMenuId !== true) setApiStatus(HandlerAPIMessage(5, validateListMenuId));
        }
        setOnSubmitForm(false);
    }

    if (!isDataComplete) return (<AppPlaceholder rows={15} classCSS={"card-no-box"} />);

    if (listMenu && listMenu.length === 0) {
        return (
            <div className={"p-5 text-center"}>
                <div className="display-1 text-300 fs-error">OPS</div>
                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">Manca poco e poi potrai creare la prima categoria.</p>
                <hr/>
                <p className={"mb-0"}>Controlla di aver creato un <b>menù</b>.</p>
                <p>Se invece pensi ci sia un errore,<a href="mailto:team@gloowe.com" className="ms-1">contattaci</a>.</p>
            </div>
        );
    }

    return (
        <>
            {
                (!submitFormStatus)
                    ?
                    <form onSubmit={formSubmit}>
                        <div className={"row mb-3"}>
                            <div className={"col-sm-12 col-md-5"}>
                                <AppInput
                                    type={"input"}
                                    structure={"field-vertical"}
                                    labelFor={"name"} content={"Nome"}
                                    class={"is-medium mb-3"}
                                    changeEvent={(e) => handleData(e)}
                                    placeHolder={"Dai un nome alla nuova categoria"}
                                    value={dataMenuCategory.name}
                                />
                                <AppInput
                                    type={"textarea"}
                                    structure={"field-vertical"}
                                    labelFor={"description"} content={"Descrizione"}
                                    class={"is-medium mb-3"}
                                    changeEvent={(e) => handleData(e)}
                                    placeHolder={"..."}
                                    value={dataMenuCategory.description}
                                />
                            </div>
                            <div className={"col-sm-12 col-md-7"}>
                                <label className="form-label">Cover della categoria</label>
                                <Dropzone preview={preview} inputFileName={"file_cover"}
                                          typeZone={'zone-rectangle-2'}
                                          fileCoverName={dataMenuCategory.file_cover.name}
                                          onInputChange={(e) => handleData(e)}
                                          onInputRemoveFile={(e) => handleRemoveDataFile(e)}>
                                    <div className="d-flex justify-content-center">
                                        <p className="fs-0 mb-0 text-700">Aggiungi una cover alla categoria</p>
                                    </div>
                                    <p className="mb-0 w-75 mx-auto text-400">Ti consigliamo di caricare un file da 820x312 px in formato jpg o png per un massimo di 3MB</p>
                                </Dropzone>
                            </div>
                            <div className={"co-12 mt-3"}>
                                <div className="field">
                                    <label className="form-label">Associa la categoria ad un o più menù</label>
                                    <div className={"simple-bar-content-wrapper"} style={{overflowX: "scroll"}}>
                                        <div className="control d-flex inline-size-max-content">
                                            <LayoutListMenu type={"list_menu_id"}
                                                            data={listMenu}
                                                            currentData={(dataMenuCategory && dataMenuCategory.list_menu_id !== undefined && dataMenuCategory.list_menu_id.length > 0 ? dataMenuCategory.list_menu_id : null)}
                                                            changeEvent={(e) => handleData(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <AppButton type={"submit"} disabled={onSubmitForm} class={"btn-primary " + (onSubmitForm ? 'is-loading' : '') + " is-block is-fullwidth is-medium"}>
                                    {!onSubmitForm ? (id ? "Modifica categoria" : "Crea la nuova categoria") : '...'}
                                </AppButton>
                            </div>
                            <div className={"col-12 pt-2"}>
                                <MessageBanner type={"banner"} is_fixed={false} has_closed={false} code={apiStatus.class} text={apiStatus.textMessage}/>
                            </div>
                        </div>
                    </form>
                    :
                    <div className="card-body text-center">
                        <h3 className="fw-semi-bold ">{id ? 'La categoria è stata aggiornata' : 'Nuova categoria creata'}</h3>
                        <p>{apiStatus.textMessage}</p>
                        <div className="text-center">
                            <button type="button" className="my-3 btn btn-outline-secondary btn-sm" onClick={props.handleClose}>Chiudi</button>
                            <small className="d-block">For any technical issues faced, please contact <a href="mailto:team@gloowe.com">Customer Support</a>.</small>
                        </div>
                    </div>
            }
        </>
    );
}

export default FormMenuCategory;