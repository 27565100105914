import './index.css';
import React, {useEffect, useState} from "react";
import AppInput from "../../utils/AppInput";
import AppButton from "../../utils/AppButton";
import InputValidation from "../../constants/input-validation";
import {AuthAPI} from "../../apis/AuthAPI";
import HandlerAPIMessage from "../../constants/handle-api-message";
import MessageBanner from "../../utils/MessageBanner";
import {useGlobalContext} from "../../constants/application-context";
import {SVG_ROW_RIGHT} from "../../utils/AppSvg";
import {Link} from "react-router-dom";
import AppPlaceholder from "../../utils/AppPlaceholder";

const FormDomain = (props) => {
    let id = props.id ?? null;
    const global = useGlobalContext();
    const [apiStatus, setApiStatus] = useState({})
    const [onSubmitForm, setOnSubmitForm] = useState(false)
    const [submitFormStatus, setSubmitFormStatus] = useState(false)
    const [isDataComplete, setIsDataComplete] = useState(false)
    const [dataMenuDomain, setDataMenuDomain] = useState({
        id: '',
        name: '',
        url: '',
        description: '',
        has_personal_domain: false,
    });

    useEffect(() => {
        async function fetchData() {
            let responseDataDomain;
            if (id !== null) {
                responseDataDomain = await AuthAPI.menuDomainItem(global.token, global.tokenStore, {menu_domain_id: id});
                if (responseDataDomain.status === 200) {
                    let data = responseDataDomain.data.status ? responseDataDomain.data.context.single : null;
                    setDataMenuDomain({
                        ...dataMenuDomain
                        , id: data.id
                        , name: data.name
                        , description: data.description
                        , url: (data.url ?? '')
                        , has_personal_domain: (data.url && data.url.length > 0)
                    });
                }
            }
        }
        fetchData().then(() => {
            setIsDataComplete(true)
        });
    }, []);

    function handleData(e) {
        let currentName = (e.currentTarget.tagName === 'BUTTON') ? e.currentTarget.dataset.name : e.target.name;
        let currentValue = (currentName === 'has_personal_domain') ? !dataMenuDomain.has_personal_domain : e.target.value;

        switch (currentName) {
            case 'name':
                setDataMenuDomain({...dataMenuDomain, name: currentValue});
                break;
            case 'has_personal_domain':
                setDataMenuDomain({...dataMenuDomain, has_personal_domain: currentValue});
                break;
            case 'url':
                setDataMenuDomain({...dataMenuDomain, url: currentValue});
                break;
            case 'description':
                setDataMenuDomain({...dataMenuDomain, description: currentValue});
                break;
            default:
                console.info('No type for ' + currentName);
                break;
        }
    }

    const formSubmit = async e => {
        setOnSubmitForm(true);
        setApiStatus(HandlerAPIMessage(undefined, null));
        e.preventDefault();
        let validateName = InputValidation(dataMenuDomain.name, 'text', 'Nome');
        let validateUrl = dataMenuDomain.has_personal_domain ? InputValidation(dataMenuDomain.url, 'url', 'Url') : true;
        if (validateName === true && validateUrl === true) {
            let dataForm = {
                menu_domain_id: dataMenuDomain.id,
                name: dataMenuDomain.name,
                has_personal_domain: dataMenuDomain.has_personal_domain,
                url: dataMenuDomain.has_personal_domain ? dataMenuDomain.url : null,
                description: dataMenuDomain.description,
            };
            let response = await AuthAPI.menuDomainSave(global.token, global.tokenStore, dataForm);
            if (response.status === 200) {
                if (response.data.status) {
                    global.setDataUpdate(!global.dataUpdate);
                    let responseContext = response.data.context !== null ? response.data.context : null;
                    let responseMessage = response.data.message !== null ? response.data.message : null;
                    if (responseContext === null || responseContext.length === 0) setApiStatus(HandlerAPIMessage(5, 'Errore imprevisto'));
                    else setApiStatus(HandlerAPIMessage(3, responseMessage));
                    setSubmitFormStatus(response.data.status);
                } else setApiStatus(HandlerAPIMessage(5, response.data.message));
            } else setApiStatus(HandlerAPIMessage(5, 'Impossibile procedere, non c\'è nessuna connessione ad internet'));
        }
        else {
            if (validateName !== true) setApiStatus(HandlerAPIMessage(5, validateName));
            else if (validateUrl !== true) setApiStatus(HandlerAPIMessage(5, validateUrl));
        }
        setOnSubmitForm(false);
    }

    if (!isDataComplete || !dataMenuDomain) return (<AppPlaceholder rows={10} classCSS={"card-no-box"} />);

    return (
        <>
            {
                (!submitFormStatus)
                    ?
                    <form onSubmit={formSubmit}>
                        <div className={"row mb-3 "}>
                            <div className={"col-12"}>
                                <AppInput
                                    type={"input"}
                                    structure={"field-vertical"}
                                    labelFor={"name"} content={"Nome"}
                                    class={"is-medium mb-3"}
                                    changeEvent={(e) => handleData(e)}
                                    placeHolder={"Assegna un nome al tuo dominio"}
                                    value={dataMenuDomain.name}
                                />
                            </div>
                            <div className={"col-12 mb-3"}>
                                <div className="field d-flex hstack mb-2">
                                    <label htmlFor="url" className="form-label flex-1 mb-0">Vuoi attivare il Menù personalizzato?</label>
                                    <button type="button" className={"btn btn-sm " + (dataMenuDomain.has_personal_domain ? "btn-success" : "btn-outline-warning")}
                                            data-name={"has_personal_domain"}
                                            onClick={(e) => handleData(e)}>{dataMenuDomain.has_personal_domain ? 'Attivo' : 'Non Attivo'}</button>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        {
                                            dataMenuDomain.has_personal_domain
                                                ?
                                                <>
                                                    <AppInput
                                                        type={"input"}
                                                        structure={"field-vertical"}
                                                        isInputGroup={true}
                                                        textInputGroup={"http(s)://"}
                                                        labelFor={"url"}
                                                        class={"is-medium mb-0"}
                                                        changeEvent={(e) => handleData(e)}
                                                        placeHolder={"Es: www.tuo-sito.com "}
                                                        value={dataMenuDomain.url}
                                                    />
                                                    <p className="fs--1 mt-2 mb-1">Hai attivato il <u>menù personalizzato</u>, assicurati di inserire la url corretta in cui i tuoi clienti possono trovare il tuo menù online, sarà nostra cura inviarti via email il <u>token</u> con cui implementare il tuo menù digitale personalizzato.</p>
                                                </>
                                                :
                                                <>
                                                    <p className="fs--1 mt-2 mb-1">Senza un <u>menù digitale personalizzato</u> potrai comunque scaricare il documento PDF del tuo menù creato su gloowe.</p>
                                                </>
                                        }
                                        <p className="fs--1 mb-1">
                                            <Link to={"/dashboard/menu"}><small>Per saperne di più<SVG_ROW_RIGHT style={"fs--2"}/></small></Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12"}>
                                <AppInput
                                    type={"textarea"}
                                    counting={true}
                                    maxCount={230}
                                    rows={3}
                                    structure={"field-vertical"}
                                    labelFor={"description"}
                                    content={"Note personali"}
                                    class={"is-medium"}
                                    changeEvent={(e) => handleData(e)}
                                    placeHolder={"..."}
                                    value={dataMenuDomain.description}
                                />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <AppButton type={"submit"} disabled={onSubmitForm} class={"btn-primary is-medium w-100"}>
                                    {!onSubmitForm ? (id ? "Modifica dominio" : "Crea nuovo dominio") : '...'}
                                </AppButton>
                            </div>
                            <div className={"col-12 pt-2"}>
                                <MessageBanner type={"banner"} is_fixed={false} has_closed={false} code={apiStatus.class} text={apiStatus.textMessage}/>
                            </div>
                        </div>
                    </form>
                    :
                    <div className="card-body text-center">
                        <h3 className="fw-semi-bold ">{id ? 'Il dominio del menù è stato aggiornato' : 'Nuovo dominio del menù creato'}</h3>
                        <p>{apiStatus.textMessage}</p>
                        <div className="text-center">
                            <button type="button" className="my-3 btn btn-outline-secondary btn-sm" onClick={props.handleClose}>Chiudi</button>
                            <small className="d-block">For any technical issues faced, please contact <a href="mailto:team@gloowe.com">Customer Support</a>.</small>
                        </div>
                    </div>
            }
        </>
    );
}

export default FormDomain;