import React from 'react';
import {SVG_SEARCH} from "../../utils/AppSvg";

const NavbarSearch = () => {
    return (<ul className="align-items-center d-none d-lg-block navbar-nav">
        <li className="nav-item">
            <div className="search-box dropdown">
                <div className="toggle" id="react-aria5385740925-1" aria-expanded="false" data-toggle="dropdown">
                    <form className="position-relative">
                        <input placeholder="Cerca..." aria-label="Search" type="search"
                               className="rounded-pill search-input form-control"/>
                        <SVG_SEARCH style={"position-absolute text-400 search-box-icon"}/>
                    </form>
                </div>
            </div>
        </li>
    </ul>);
};

export default NavbarSearch;