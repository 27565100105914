import './index.css';
import React from "react";
import {motion} from "framer-motion";
import Backdrop from "../backdrop/index";
import {SVG_ICON_CLOSE} from "../../utils/AppSvg";

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};

const Modal = ({handleClose, item, children, dimension = 'modal-normal', modalCss = ''}) => {
    if (item && (item.action === 'create-domain' || item.action === 'edit-domain')) dimension = 'modal-xs';
    else if (item && (item.action === 'create-menu' || item.action === 'edit-menu')) dimension = 'modal-sm';

    return (
        <Backdrop onClick={handleClose}>
            <motion.div onClick={(e) => e.stopPropagation()}
                        className={"position-relative modal " + dimension}
                        variants={dropIn} initial="hidden" animate="visible" exit="exit">
                {
                    handleClose && <button type="button" className={"position-absolute px-btn close"} onClick={handleClose}><SVG_ICON_CLOSE/></button>
                }
                {children}
                {/*}
                <div className={"modal-container card " + modalCss}>
                    {
                        item && item.action !== 'status-archived' &&
                        <div className="modal-header position-relative p-0 pb-2">
                            <h4 className="font-alt fs-1 modal-title color-brand-dark col-12 text-center">{item.title}</h4>
                        </div>
                    }
                    <div className="modal-body fw-normal card-body">
                        {children}
                    </div>
                </div>*/}
            </motion.div>
        </Backdrop>
    );
};
export default Modal