import React from "react";
import ListMasterFooter from "./footer";
import {AnimatePresence} from "framer-motion";
import {ItemMenu, ItemMenuCategory, ItemMenuDomain} from "./item";


const ListMaster = ({data, isLoading, pageChange, children}) => {
    return (
        <>
            <div className="mb-3 card">
                <div className="border-bottom border-200 card-header">{children}</div>
            </div>
            <div className="mb-3">
                {
                    data.list && data.list.map((item, key) => {
                        switch (data.type) {
                            case 'list-menu': return <ItemMenu data={item}/>;
                            case 'list-menu-domain': return <ItemMenuDomain data={item}/>;
                            case 'list-menu-category': return <ItemMenuCategory data={item}/>;
                            case 'list-menu-consumable': return <ItemMenuCategory data={item}/>;
                            default: return (<div className={"card"}><div className={"card-body text-center"}>Nessun dato da mostrare</div></div>);
                        }
                    })
                }
            </div>
            {
                data.pagination && data.pagination.total !== data.pagination.to &&
                <div className="mb-3 card">
                    <div className="card-footer">
                        <ListMasterFooter pagination={data.pagination} pageChange={pageChange}/>
                    </div>
                </div>
            }
        </>
    );
};

export default ListMaster;