import React from "react";
import {SVG_NEXT, SVG_PREVIUS} from "../../utils/AppSvg";

const ListMasterFooter = ({pagination, pageChange}) => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            {
                pagination.prev_page_url &&
                <button type="button" className="btn btn-falcon-default btn-sm"
                        onClick={() => pageChange(1)}><SVG_PREVIUS type={'md'}/></button>
            }
            <ul className="pagination mb-0 mx-1">
                {
                    pagination.links &&
                    pagination.links
                        .filter((item,key) => { return (key !== 0 && key !== (pagination.links.length - 1)); })
                        .map((item, key) =>
                            <li key={key.toString()} className={item.active ? 'active' : ''}>
                                <button type="button" className="page me-1 btn btn-falcon-default btn-sm"
                                        onClick={() => pageChange(item.label)}>{item.label}</button>
                            </li>
                        )
                }
            </ul>
            {
                pagination.next_page_url &&
                <button type="button" className="btn btn-falcon-default btn-sm" onClick={() => pageChange(pagination.links.length)}>
                    <SVG_NEXT type={'md'}/>
                </button>
            }
        </div>
    );
}

export default ListMasterFooter;