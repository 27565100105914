import './index.css';
import React, {useEffect, useState} from "react";
import {Link, Navigate, useLocation} from "react-router-dom";
import {useGlobalContext} from "../../constants/application-context";
import {
    SVG_CREDIT_CARD,
    SVG_DASHBOARD,
    SVG_EMAIL,
    SVG_GLOBE,
    SVG_HOME,
    SVG_LAYER_GROUP, SVG_PLUS,
    SVG_PROFILE,
    SVG_PUZZLE,
    SVG_SECURITY,
    SVG_SHAPES,
    SVG_STORE,
} from "../../utils/AppSvg";
import AppBrandLogo from "../../utils/AppBrandLogo";
import NavbarCard from "./card";
import NavbarSearch from "./search";
import CreateComponent from "./create";
import AppButton from "../../utils/AppButton";
import {getFileUrl} from "../../utils/Utils";

const NAVBAR_ACCOUNT_ROUTER = [
    {type: 'line', name: 'account setting'},
    {type: 'item', name: 'account', destination: '/settings/account', icon: <SVG_PROFILE/>},
    {type: 'item', name: 'login & security', destination: '/settings/security', icon: <SVG_SECURITY/>},
    {type: 'item', name: 'email preferences', destination: '/settings/email-preferences', icon: <SVG_EMAIL/>},
    {type: 'line', name: 'store setting'},
    {type: 'item', name: 'store', destination: '/settings/store', icon: <SVG_STORE/>},
    {type: 'item', name: 'store info', destination: '/settings/store-info', icon: <SVG_STORE/>},
    {type: 'item', name: 'team', destination: '/settings/team', icon: <SVG_STORE/>},
    {type: 'item', name: 'billing & plans', destination: '/settings/store-billing-plans', icon: <SVG_CREDIT_CARD/>},
];

const NAVBAR_ROUTER = [
    {type: 'item', name: 'dashboard', destination: '/dashboard', icon: <SVG_DASHBOARD/>},
    {type: 'line', name: 'gestione ordinazioni'},
    {type: 'item', name: 'leggi ordine', destination: '/orders/add', icon: <SVG_PLUS/>},
    {type: 'item', name: 'elenco ordini', destination: '/orders/list', icon: <SVG_LAYER_GROUP/>},
    {type: 'line', name: 'menù digitale'},
    {type: 'item', name: 'domini menù', destination: '/dashboard/menu-domain', icon: <SVG_GLOBE/>},
    {type: 'item', name: 'carte menù', destination: '/dashboard/menu', icon: <SVG_LAYER_GROUP/>},
    {type: 'item', name: 'categorie', destination: '/dashboard/menu-category', icon: <SVG_PUZZLE/>},
    {type: 'item', name: 'portate', destination: '/dashboard/menu-consumable', icon: <SVG_SHAPES/>},
    {type: 'line', name: 'settings'},
    {type: 'item', name: 'account', destination: '/settings/account', icon: <SVG_PROFILE/>},
    {type: 'item', name: 'store', destination: '/settings/store', icon: <SVG_STORE/>},
];

const NAVBAR_NO_AUTH = [
    {type: 'item', name: 'Email: team@gloowe.com', destination: 'mailto:team@gloowe.com', icon: <SVG_EMAIL/>},
];

const NavbarItem = ({item, currentPathname = null}) => {
    if (item.type === 'item') {
        return (
            <li className="nav-item">
                <Link to={item.destination}
                      className={"nav-link " + (currentPathname === item.destination ? 'active' : '')}>
                    <div className="d-flex align-items-center">
                        <span className="nav-link-icon">{item.icon}</span>
                        <span className="nav-link-text ps-1">{item.name}</span>
                    </div>
                </Link>
            </li>
        )
    } else if (item.type === 'line') {
        return (
            <li className="nav-item">
                <div className="mt-3 mb-2 navbar-vertical-label-wrapper row">
                    <div
                        className="navbar-vertical-label navbar-vertical-label col-auto">{item.name.toUpperCase()}</div>
                    <div className="ps-0 col">
                        <hr className="mb-0 navbar-vertical-divider"/>
                    </div>
                </div>
            </li>
        );
    }
}

const NavbarDashboard = () => {
    const global = useGlobalContext();
    const location = useLocation();
    const [buttonOperation, setButtonOperation] = useState(<></>);
    let current_NAVBAR_ROUTER = (location.pathname.includes('/settings')) ? NAVBAR_ACCOUNT_ROUTER : NAVBAR_ROUTER;

    useEffect(() => {
        if (location.pathname.includes('/settings')) setButtonOperation(<div className="ms-auto col-auto pt-1 position-relative"><Link to={"/dashboard"} type="button" className={"btn btn-outline-primary"}>Back to Dashboard</Link></div>)
        else setButtonOperation(<CreateComponent/>);
    }, [location]);

    return (
        <div className={"navbar-vertical navbar navbar-expand-md navbar-light"}>
            <div className={"d-flex align-items-center"}>
                <div className={"toggle-icon-wrapper d-none"}>
                    <button type="button" id="toggleNavigationTooltip"
                            className={"navbar-toggler-humburger-icon navbar-vertical-toggle btn btn-link"}>
                        <span className={"navbar-toggle-icon"}><span className={"toggle-line"}></span></span>
                    </button>
                </div>
                <Link to={"/"} className={"text-decoration-none navbar-brand text-left"}>
                    <div className="d-flex align-items-center py-3">
                        <AppBrandLogo type={"small"}/>
                    </div>
                </Link>
            </div>
            <div className={"navbar-collapse collapse" + (global.showMenuDashboard ? ' show' : '')}>
                {buttonOperation}
                <div className={"navbar-vertical-content scrollbar gloowe-dashboard"}>
                    <ul className={"flex-column navbar-nav"}>
                        {
                            current_NAVBAR_ROUTER.map((item, key) =>
                                <NavbarItem key={key.toString()} item={item} currentPathname={location.pathname}/>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}

const NavbarProfile = () => {
    const global = useGlobalContext(); //authData
    const [cardSelected, setCardSelected] = useState(0);
    const [navbarScrolling, setNavbarScrolling] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [typeScroll, setTypeScroll] = useState('UP_SCROLL');
    const [cardCurrentStore, setCardCurrentStore] = useState(<></>);

    useEffect(()=> {
        let currentStore = null;
        if (global.authData !== null && global.tokenStore !== null && global.authData.list_store !== null) {
            currentStore = global.authData.list_store.filter(object => {
                return object.id === parseInt(global.tokenStore);
            });
        }
        if (currentStore !== null) {
            let image_store_logo = getFileUrl(currentStore[0], 'url_store_logo');
            setCardCurrentStore(
                <div className="nav-item">
                    <div className={"card"}>
                        <div className={"card-body p-2 rounded-1 bg-gloowe-orange"}>
                            <div className={"d-flex justify-content-start align-items-center"}>
                                <div className="avatar avatar-2xl cursor-pointer">
                                    {
                                        image_store_logo !== null
                                            ? <img src={image_store_logo} alt={currentStore[0].business_name}
                                                   className="img-fluid rounded-1 zone-image zone-square zone-square-125"
                                                   style={{border: 'solid 1px var(--gloowe-orange)'}}/>
                                            : <div className="avatar-name rounded-circle fs-2 bg-default"><span>{currentStore[0].business_name.charAt(0)}</span></div>
                                    }
                                </div>
                                <div className={"ms-1 me-0 text-white fw-bold"}>{currentStore[0].business_name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            setCardCurrentStore(
                <div className="nav-item">
                    <div className={"card"}>
                        <div className={"card-body p-2 px-3 rounded-1"} style={{border: 'solid 1px var(--gloowe-orange)'}}>...</div>
                    </div>
                </div>
            )
        }

    }, [global.authData]);
    const changeNavbarProfile = () => {
        let st = window.scrollY || document.documentElement.scrollTop;
        setNavbarScrolling(window.scrollY >= 40);
        if (st > lastScrollTop) setTypeScroll('DOWN_SCROLL');
        else if (st < lastScrollTop) {
            setTypeScroll('UP_SCROLL');
            global.setShowMenuDashboard(false);
        }
        setLastScrollTop((st <= 0) ? 0 : st);
    }
    window.addEventListener('scroll', changeNavbarProfile);

    function toggleMenuDashboard() {
        global.setShowMenuDashboard(!global.showMenuDashboard)
    }

    return (
        <>
            <div
                className={"navbar-glass fs--1 navbar-top navbar navbar-expand navbar-light mb-2 " + (navbarScrolling ? 'navbar-glass-shadow' : '')}>
                <div className={"d-flex align-items-center"}>
                    <Link to={"/"} className={"text-decoration-none navbar-brand text-left m-0 me-sm-4"}>
                        <div className="d-flex align-items-center p-0">
                            <AppBrandLogo type={"small"}/>
                        </div>
                    </Link>
                </div>
                <NavbarSearch/>
                <div className="flex-row align-items-center navbar-nav ms-auto">
                    {/*<li className="d-none d-sm-block nav-item">
                        <Link to={"shopping-cart"}
                              className="px-0 notification-indicator notification-indicator-warning position-relative notification-indicator-fill nav-link">
                            <span className="notification-indicator-number">9</span>
                            <SVG_SHOPPING_CARD/>
                        </Link>
                    </li>*/}
                    {/*<li className="dropdown">
                        <div className="px-0 nav-link notification-indicator notification-indicator-primary toggle"
                             onClick={() => setCardSelected(cardSelected === 1 ? 0 : 1)}>
                            <SVG_BELL/>
                        </div>
                        <NavbarCard type={"notification"} card_selected={cardSelected}/>
                    </li>*/}
                    {cardCurrentStore}
                    <div className="nav-item ms-3">
                        <div className="justify-content-between align-items-center d-flex " onClick={() => setCardSelected(cardSelected === 2 ? 0 : 2)}>
                            <div className="avatar avatar-3xl cursor-pointer">
                                <div className="avatar-name rounded-1 fs-4 bg-primary text-white">
                                    <span>{global.authData ? (global.authData.profile.first_name.charAt(0) ?? '-') : '-'}</span>
                                </div>
                            </div>
                        </div>
                        <NavbarCard type={"profile"} card_selected={cardSelected}/>
                    </div>
                </div>
            </div>
            <div
                className={"navbar-glass fs--1 navbar-bottom navbar navbar-light d-block d-lg-none bg-white navbar-glass-shadow-top " + (typeScroll === 'UP_SCROLL' ? 'd-none' : '')}>
                <div className={"d-flex d-inline-flex align-items-center align-content-center w-100"}>
                    <div className={"col-4 text-center"}>
                        <div className="nav-link toggle" onClick={() => <Navigate to="/" replace/>}>
                            <SVG_HOME/>
                        </div>
                    </div>
                    <div className={"col-4 text-center"}>
                        <CreateComponent isMobile={true}/>
                    </div>
                    <div className={"col-4 text-center"}>
                        <div aria-label="toggle gloowe"
                             className="toggle-icon-wrapper text-center d-xl-none navbar-toggler collapsed">
                            <button onClick={() => toggleMenuDashboard()}
                                    className="m-auto navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
                                    id="burgerMenuGloowe">
                                <span className="navbar-toggle-icon"><span className="toggle-line"></span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const NavbarNoAuthItem = ({item, currentPathname = null}) => {
    if (item.type === 'item') {
        return (
            <div className="nav-item">
                <Link to={item.destination}
                      className={"nav-link fw-semi-bold text-white fs--1 px-4" + (currentPathname === item.destination ? 'active' : '')}>{item.name}</Link>
            </div>
        );
    } else if (item.type === 'dropdown') {
        return (
            <div className="dropdown">
                <Link to={item.destination}
                      className={"nav-link fw-semi-bold " + (currentPathname === item.destination ? 'active' : '')}>
                    {item.name.toUpperCase()}
                </Link>
            </div>
        );
    }
}

const NavbarNoAuth = () => {
    const location = useLocation();
    const isNavCollapsed = false;
    const [navbarScrolling, setNavbarScrolling] = useState(false);
    const changeNavbarProfile = () => {
        setNavbarScrolling(window.scrollY >= 35);
    }
    window.addEventListener('scroll', changeNavbarProfile);

    return (
        <nav
            className={"navbar-standard navbar navbar-expand-md navbar-dark py-3 py-md-3 fixed-top " + (navbarScrolling ? 'bg-primary navbar-glass-shadow' : '')}>
            <div className="container justify-content-between">
                <AppBrandLogo type={'normal-horizontal'}/>
                <div className="navbar-toggler collapsed" data-toggle="collapse">
                    <AppButton type={"link"} destination={"/login"}
                               class={"btn " + (navbarScrolling ? 'btn-dark' : ' btn-orange') + " rounded-5 w-100"}>Accedi</AppButton>
                </div>
                <div className={"scrollbar navbar-collapse collapse pb-3 pb-md-0 " + (isNavCollapsed ? 'show' : '')}>
                    <div className={"navbar-nav"}>
                        {
                            NAVBAR_NO_AUTH.map((item, key) =>
                                <NavbarNoAuthItem key={key.toString()} item={item} currentPathname={location.pathname}/>
                            )
                        }
                    </div>
                    <div className="ms-auto navbar-nav">
                        <div className="nav-item">
                            <Link to={"/login"} className={"nav-link fw-semi-bold text-white fs--1 px-4"}>Accedi</Link>
                        </div>
                        <div className="nav-item">
                            <AppButton type={"link"} destination={"/register"}
                                       class={"btn " + (navbarScrolling ? 'btn-dark' : ' btn-primary') + " rounded-5 w-100"}>Registrati</AppButton>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

const Navbar = ({type = 'dashboard'}) => {
    let htmlNavbar;
    if (type === 'dashboard') htmlNavbar = NavbarDashboard();
    else if (type === 'profile') htmlNavbar = NavbarProfile();
    else if (type === 'no-auth') htmlNavbar = NavbarNoAuth();
    return htmlNavbar;
}

export default Navbar;