import React, {useEffect, useRef, useState} from "react";
import './AppInput.css';
import PropTypes from 'prop-types';
import {SVG_INDICATOR_DOWN} from "./AppSvg";
import AppAutocomplete from "./AppAutocomplete";

const CustomSelectSingle = (props) => {
    const [listItemSelected, setListItemSelected] = useState(<></>)

    useEffect(() => {
        let currentItemSelected = <></>;
        props.value && props.options && props.options.map((item) => {
            let p_id = item.value ?? item.id;
            if (p_id === props.value) currentItemSelected = <div className={"react-select__single-value css-qc6sy-singleValue"}>{item.name}</div>;
        })
        setListItemSelected(currentItemSelected);
    }, [props.options, props.value]);

    function openDropDown(event) {
        let select_menu = event.currentTarget.parentNode.querySelector('div.react-select__menu');
        if (select_menu.classList.contains('d-none')) select_menu.classList.remove('d-none');
        else select_menu.classList.add('d-none');
    }

    function selectOption(event) {
        let dataValue = event.currentTarget.dataset.value ?? null;
        let dataName = event.currentTarget.dataset.name ?? null;
        let dateRef = event.currentTarget.dataset.ref ?? null;

        //document.getElementById('container_' + dateRef).innerHTML = '<div className={"react-select__single-value css-qc6sy-singleValue"}>' + dataName + '</div>';

        let ev = new Event('input', { bubbles: true});
        document.getElementById(dateRef).value = dataValue;
        document.getElementById(dateRef).dispatchEvent(ev);

        let currentItemSelected = <div className={"react-select__single-value css-qc6sy-singleValue"}>{dataName}</div>;
        setListItemSelected(currentItemSelected);

        event.currentTarget.parentNode.parentNode.parentNode.querySelector('div.react-select__menu').classList.add('d-none');
    }

    return <>
        <div className={"control"} style={{position: "relative"}}>
            <div className={"form-control react-select__control css-1s2u09g-control"} onClick={(e) => openDropDown(e)}>
                <div className={"react-select__value-container react-select__value-container--has-value css-1d8n9bt"} id={"container_" + props.name}>
                    {listItemSelected}
                </div>
                <div className={"react-select__indicators css-1wy0on6"}>
                    <input id={props.name} type={"hidden"}
                           name={props.name} onInput={props.onChangeEvent}
                           value={props.value} disabled={props.status}/>
                    <span className={"react-select__indicator-separator css-1okebmr-indicatorSeparator"}></span>
                    <div
                        className={"react-select__indicator react-select__dropdown-indicator css-tlfecz-indicatorContainer"}
                        aria-hidden="true">
                        <SVG_INDICATOR_DOWN/>
                    </div>
                </div>
            </div>
            <div className={"react-select__menu css-26l3qy-menu d-none"}>
                <div className={"react-select__menu-list css-11unzgr"}>
                    {
                        props.options && props.options.map((item, key) => {
                            let p_id = item.value ?? item.id;
                            return (
                                <div key={key.toString()} className="react-select__option css-yt9ioa-option"
                                     aria-disabled="false" tabIndex="-1"
                                     data-value={p_id} data-name={item.name} data-ref={props.name}
                                     onClick={(e) => selectOption(e)}>{item.layout}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    </>
}

function CustomInput(props) {
    switch (props.type) {
        case "checkbox":
            return (
                <div className={"field is-fullwidth"}>
                    <input type="checkbox"
                           id={props.name}
                           name={props.name}
                           className={"switch is-rounded"}
                           onChange={props.onChangeEvent}
                           defaultChecked={props.value}/>
                    <label htmlFor={props.name.toLowerCase()}>
                        {props.content ?? ''}
                        {props.help && <span className={"help"}>{props.help}</span>}
                    </label>
                </div>
            )
        case "file":
            return (
                <div className={"file is-fullwidth"}>
                    <label className={"file-label"}>
                        <input type="file"
                               name={props.labelFor}
                               className={"file-input"}
                               onChange={props.onChangeEvent}/>
                        <span className="file-cta">
                        <span
                            className="file-label has-text-weight-bold has-text-primary">{props.content ?? 'Seleziona un file'}</span>
                    </span>
                    </label>
                </div>
            );
        case "select":
            return (
                <CustomSelectSingle
                    name={props.labelFor}
                    closeMenuOnSelect={false}
                    options={props.options ?? null}
                    className={"form-control " + props.class}
                    style={!props.status ? {opacity: 1} : {opacity: 0.5}}
                    placeholder={!props.status ? (props.placeHolder) : "seleziona ..."}
                    onChangeEvent={props.onChangeEvent}
                    value={props.value}
                    disabled={props.status}/>
            );
        case "select-old":
            return (
                <div className={"select form-control is-fullwidth"}>
                    <select onChange={props.onChangeEvent} value={props.value} className={"has-gw-style"}>
                        <option value={1}>Uomo</option>
                        <option value={2}>Donna</option>
                        <option value={3}>Altro</option>
                    </select>
                </div>
            );
        case "textarea":
            return (
                <textarea
                    type={"textarea"}
                    name={props.labelFor}
                    className={"textarea form-control " + props.class}
                    style={!props.status ? {opacity: 1} : {opacity: 0.5}}
                    placeholder={!props.status ? (props.placeHolder) : "caricamento..."}
                    onChange={props.onChangeEvent}
                    onKeyUp={props.onCounting}
                    disabled={props.status}
                    value={props.value}
                    rows={props.rows ?? 4}/>
            );
        case "input":
        case "email":
        case "password":
        case "date":
        case "time":
        case "number":
            return (
                <input
                    id={props.labelFor}
                    type={props.type}
                    name={props.labelFor}
                    className={"form-control " + props.class}
                    style={!props.status ? {opacity: 1} : {opacity: 0.5}}
                    placeholder={!props.status ? (props.placeHolder) : "caricamento..."}
                    onChange={props.onChangeEvent}
                    value={props.value}
                    disabled={props.status}
                    readOnly={props.readOnly ?? false}/>
            );
        case "search":
            return (
                <input
                    id={props.labelFor}
                    type={props.type}
                    name={props.labelFor}
                    className={"shadow-none form-control " + props.class}
                    style={!props.status ? {opacity: 1} : {opacity: 0.5}}
                    placeholder={!props.status ? (props.placeHolder) : "cerca..."}
                    onChange={props.onChangeEvent}
                    value={props.value}
                    disabled={props.status}/>
            );
        case "google-autocomplete":
            return <AppAutocomplete onChange={props.onChangeEvent}
                                    class={props.class} status={props.status}
                                    handlerData={props.handlerData}/>
        default:
            return (<></>);
    }
}

const AppInput = (props) => {
    let inputClass = props.class ?? '';
    let showLabel = (props.content !== undefined && props.content.length > 0);
    let maxCount = props.maxCount ?? 250;
    const [countCharacter, setCountCharacter] = useState(0);

    function countingText(e) {
        let text = e.target.value;
        setCountCharacter(text.length);
    }

    if (props.structure === 'field-vertical') {
        return (
            <div className={"field"}>
                <div className={"d-flex"}>
                    {showLabel && <label htmlFor={props.labelFor} className={"form-label " + (props.classLabel ?? '')} style={{textAlign: 'left', width: '100%'}}>{props.content}</label>}
                    {props.counting &&
                        <p className={"fs--2 pt-1 mb-0 " + ((maxCount - countCharacter < 10) ? 'text-danger' : '')}>{countCharacter}/{maxCount}</p>}
                </div>
                <div className={(props.isInputGroup ? 'input-group' : 'control')}>
                    {props.isInputGroup && props.textInputGroup &&
                        <span className="d-none d-sm-block input-group-text">{props.textInputGroup}</span>}
                    <CustomInput type={props.type}
                                 rows={props.rows ?? 4}
                                 labelFor={props.labelFor}
                                 options={props.options}
                                 class={inputClass}
                                 status={props.status}
                                 placeHolder={props.placeHolder}
                                 onChangeEvent={props.changeEvent}
                                 onCounting={props.counting ? (e) => countingText(e) : null}
                                 handlerData={props.handlerData}
                                 readOnly={props.readOnly}
                                 value={props.value}/>
                </div>
            </div>
        );
    } else if (props.structure === 'field-horizontal') {
        return (
            <div className={"field is-horizontal"}>
                <div className={"field input-group"}>
                    {showLabel && <span className={"input-group-text " + (props.classLabel ?? '')} >{props.content}</span>}
                    <CustomInput type={props.type}
                                 rows={props.rows ?? 4}
                                 labelFor={props.labelFor}
                                 options={props.options}
                                 content={props.content}
                                 class={inputClass}
                                 status={props.status}
                                 placeHolder={props.placeHolder}
                                 onChangeEvent={props.changeEvent}
                                 handlerData={props.handlerData}
                                 readOnly={props.readOnly}
                                 value={props.value}/>
                    {props.help && <span className={"help"}>{props.help}</span>}
                </div>
            </div>
        )
    } else if (props.structure === 'no-field') {
        return (
            <div className="control">
                <CustomInput type={props.type}
                             rows={props.rows ?? 4}
                             labelFor={props.labelFor}
                             options={props.options}
                             content={props.content}
                             class={inputClass}
                             status={props.status}
                             placeHolder={props.placeHolder}
                             onChangeEvent={props.changeEvent}
                             handlerData={props.handlerData}
                             readOnly={props.readOnly}
                             value={props.value}
                             help={props.help}/>
                {props.type !== "checkbox" && props.help && <span className={"help"}>{props.help}</span>}
            </div>
        )
    } else if (props.structure === 'field-middle') {
        return (
            <div className={"field position-relative"}>
                {showLabel && <label htmlFor={props.labelFor} className={"form-label position-absolute px-2 fw-semi-bold"} style={{top: '-8px', left: '8px', background: '#eef2fa', borderRadius: '5px', fontSize: '11px'}}>{props.content}</label>}
                <CustomInput type={props.type}
                             rows={props.rows ?? 4}
                             labelFor={props.labelFor}
                             options={props.options}
                             content={props.content}
                             class={inputClass}
                             status={props.status}
                             placeHolder={props.placeHolder}
                             onChangeEvent={props.changeEvent}
                             handlerData={props.handlerData}
                             readOnly={props.readOnly}
                             value={props.value}/>
            </div>
        );
    }
};

AppInput.propTypes = {
    structure: PropTypes.string,
    type: PropTypes.string,
    isInputGroup: PropTypes.bool,
    textInputGroup: PropTypes.string,
};

AppInput.defaultProps = {
    structure: 'no-field',
    type: 'input',
    isInputGroup: false,
    textInputGroup: null,
};

export default AppInput;
