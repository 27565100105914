import React from "react";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import PropTypes from "prop-types";
import AppInput from "./AppInput";

const AppButton = (props) => {
    if (props.type === "link") {
        return (
            <Link
                id={props.id ?? "gloowe_link"}
                to={props.destination ?? '#'}
                className={props.class}
                onClick={props.action ?? null}>{props.children}</Link>
        );
    }
    else {
        return (
            <motion.button
                whileHover={{scale: 1.015}} whileTap={{scale: 0.95}}
                id={props.id ?? "gloowe_btn"}
                type={props.type}
                className={"btn " + props.class}
                onClick={props.action ?? null}
                style={{opacity: props.disabled ? 0.5 : 1}}
                disabled={props.disabled}>{props.children}</motion.button>
        );
    }
};

AppButton.propTypes = {
    type: PropTypes.string,
    class: PropTypes.string,
};

AppButton.defaultProps = {
    type: 'button',
    class: '',
};

export default AppButton;
