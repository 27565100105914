import React, {useContext, useEffect, useState} from "react";
import {AuthAPI} from "../apis/AuthAPI";

const AppContext = React.createContext();

// This function creates a general context that allows access to the data within it from the entire application
// It accepted an element as a child "In this case APP component"
const Context = ({children}) => {
    const [token, setToken] = useState(localStorage.getItem("token") ?? null);
    const [tokenStore] = useState(localStorage.getItem("store_id_selected") ?? null);
    const [authData, setAuthData] = useState(null);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [showMenuDashboard, setShowMenuDashboard] = useState(false);

    useEffect(() => {
        if (token !== null && authData == null) {
            setToken(localStorage.getItem("token"));
            fetchData().then((resultData) => {
                if (resultData.status) setAuthData(resultData.context);
                else {
                    setAuthData(null);
                    setToken(null);
                    console.info('LogIn Failed');
                }
            }).catch(exception => {
                setAuthData(null);
                setToken(null);
                console.info(exception);
            });
        } else console.log('GLOBAL TOKEN IS NULL');
    }, [authData, token]);

    async function fetchData() {
        let dataMe = (tokenStore !== null) ? {store_selected: tokenStore} : null;
        let responseDataMe = await AuthAPI.me(token, dataMe);
        if (responseDataMe.status === 200) {
            return responseDataMe.data;
        } else return null;
    }


    return (
        <AppContext.Provider value={{token, tokenStore, authData, dataUpdate, setDataUpdate, showMenuDashboard, setShowMenuDashboard}}>
            {children}
        </AppContext.Provider>
    );
}

// This very useful hook allow you to access context data without call useContext hook in every Component
const useGlobalContext = () => {
    return useContext(AppContext);
}
export {useGlobalContext, Context};
