import PropTypes from "prop-types";

const HandlerAPIMessage = (code, content) => {
    if (code === 0) content = '';
    return {class: code, textMessage: content};
}

HandlerAPIMessage.propTypes = {
    code: PropTypes.number.isRequired
};

export default HandlerAPIMessage;
