import {Link, useParams} from "react-router-dom";
import {useGlobalContext} from "../../constants/application-context";
import React, {useEffect, useState} from "react";
import {AuthAPI} from "../../apis/AuthAPI";
import AppPlaceholder from "../../utils/AppPlaceholder";
import IconPlate from "../../assets/images/icon-plate.png";
import AppBreadcrumb from "../../utils/AppBreadcrumb";
import {SVG_EDIT} from "../../utils/AppSvg";
import ModalContainer from "../modal_container";
import Modal from "../modal";
import FormMenuItem from "../form/menu_item";
import HandlerStatusArchive from "../../constants/handle-status-archive";
import {getFileUrl} from "../../utils/Utils";
import HandlerRemoveFile from "../../constants/handle-remove-file";

const RenderConsumable = ({data}) => {
    const [selectedModal, setSelectedModal] = useState(null);
    let item = data.single ?? null;
    let list_category = data.list_category ?? null;
    let image_menu_consumable = getFileUrl(item, 'url_menu_consumable_cover', IconPlate);
    const [checkIsActive, setCheckIsActive] = useState(!!item.is_active);

    function submitStatusActive(e, id) {
        e.preventDefault();
        let token = localStorage.getItem("token") ?? null;
        let tokenStore = localStorage.getItem("store_id_selected") ?? null;
        let dataForm = {ref_id: id, type: 'menu-consumable'};
        AuthAPI.statusActive(token, tokenStore, dataForm).then(r => setCheckIsActive(!checkIsActive));
    }

    return (
        <>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className="p-0 mb-3 card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <div className="position-relative h-sm-100 overflow-hidden">
                                        <img src={image_menu_consumable} className="fit-cover rounded-1 w-sm-100 h-sm-100 rounded img-fluid" alt={data.name}/>
                                        {
                                            image_menu_consumable !== IconPlate &&
                                            <HandlerRemoveFile handlerRefreshData={image_menu_consumable} type={'cover'} />
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="d-flex justify-content-start align-content-between">
                                        <h5 className={"flex-1"}>{item.name}</h5>
                                        <label className="switch m-0">
                                            <input type="checkbox" checked={checkIsActive} onChange={(e) => submitStatusActive(e,item.id)}/>
                                            <div className="slider"></div>
                                            <div className="slider-card">
                                                <div className="slider-card-face slider-card-front"></div>
                                                <div className="slider-card-face slider-card-back"></div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className={"d-flex flex-column justify-content-between"}>
                                        <div style={{minHeight: '80px'}}></div>
                                        <h4 className="d-flex align-items-center">
                                            <span className="text-warning me-2">€{parseFloat(item.price).toFixed(2)}</span>
                                        </h4>
                                        <p className="fs--1 mb-1"><span>Creato il: </span><strong>{item.created_at}</strong>
                                        </p>
                                        {
                                            item.list_allergen_id &&
                                            <div className="fs--1 mb-3">Allergeni: {
                                                JSON.parse(item.list_allergen_id).map((item, key) =>
                                                    <div key={key.toString()}
                                                         className="mx-2 badge badge-soft-info rounded-pill">{item}</div>
                                                )
                                            }
                                            </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="d-flex justify-content-end col-12 mt-3">
                                            <div className="px-0 col-auto">
                                                <button type="button"
                                                        className="px-4 px-sm-5 btn btn-falcon-default btn-sm"
                                                        onClick={() => setSelectedModal(data)}>
                                                    <SVG_EDIT/>
                                                    <span className="d-none d-xl-inline-block ms-1">Modifica</span>
                                                </button>
                                            </div>
                                            <div className="ps-3 col-auto">
                                                <HandlerStatusArchive isArchive={(item.is_archive === 1)} typeHandle={'menu-consumable'} refId={item.id} item={item}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-12"}>
                    <div className="p-0 card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <h5 className="fs-0">Presente nelle categorie</h5>
                                    {
                                        list_category && list_category.map((item, key) =>
                                            <Link key={key.toString()} to={"/menu-category/" + item.id}
                                                  className="badge border link-dark text-decoration-none me-1">{item.name}</Link>
                                        )
                                    }
                                </div>
                                <div className="col-12 col-sm-8">
                                    <p>{item.description ?? 'Nessuna descrizione'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalContainer>
                {
                    selectedModal &&
                    <Modal handleClose={() => setSelectedModal(null)} item={{name: 'piatto', title: item.name, action: 'edit-menu-item'}}>
                        <div className={"modal-container card overflow-hidden"}>
                            <div className="card-header"><h4 className="font-alt fs-1 modal-title color-brand-dark col-12 text-center">Modifica Menu Item</h4></div>
                            <FormMenuItem id={item.id} handleClose={() => setSelectedModal(null)}/>
                        </div>
                    </Modal>
                }
            </ModalContainer>
        </>
    );
}

const SingleMenuConsumable = () => {
    const {id} = useParams();
    const global = useGlobalContext();
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let data = {menu_consumable_id: id};
            let responseData = await AuthAPI.menuConsumableItem(global.token, global.tokenStore, data);
            if (responseData.status === 200) setData(responseData.data);
        }

        fetchData().then(() => setLoadingData(false));
    }, [global.token, global.tokenStore, id, loadingData]);

    return (
        <>
            <AppBreadcrumb>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/dashboard"}>Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to={"/dashboard/menu-consumable"} role="button" tabIndex="0">Lista delle Portate</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Piatto</li>
                </ol>
            </AppBreadcrumb>
            <div className={"row mt-3"}>
                <div className={"col-8"}>
                    {
                        loadingData
                            ?
                            <div className={"row"}>
                                <div className={"col-12 mb-3"}><AppPlaceholder rows={7}/></div>
                                <div className={"col-12"}><AppPlaceholder/></div>
                            </div>
                            : <RenderConsumable data={data.context}/>
                    }
                </div>
                <div className={"col-4"}>
                    <AppPlaceholder/>
                </div>
            </div>
        </>
    );
}

export default SingleMenuConsumable;