import React from "react";
import AppBrandLogo from "./AppBrandLogo";
import {Link} from "react-router-dom";
import {SVG_HOME} from "./AppSvg";

const Page404 = () => {
  return (
      <>
          <section className="py-0">
              <div className="container">
                  <div className="flex-center min-vh-100 py-6 row">
                      <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-11">
                          <div className="d-flex flex-center fw-bolder fs-5 mb-4">
                              <AppBrandLogo type={"small"} />
                          </div>
                          <div className="text-center card">
                              <div className="p-5 card-body">
                                  <div className="display-1 text-300 fs-error fs-7 fs-sm-8 fs-md-9">404</div>
                                  <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold"><strong className={"me-1"}>Ops!</strong>La pagina che stai cercando non è stata trovata.</p>
                                  <hr/>
                                  <p>Assicurati che l'indirizzo sia corretto e che la pagina non sia stata spostata. Se pensi che questo sia un errore, <a href="mailto:team@gloowe.com" className="ms-1">contattaci</a>.</p>
                                  <Link className="btn btn-primary mt-3" to={"/"}><SVG_HOME/> Torna alla home</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </>
  );
}

export default Page404;