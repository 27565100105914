import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useGlobalContext} from "../../constants/application-context";
import AppBreadcrumb from "../../utils/AppBreadcrumb";
import {AuthAPI} from "../../apis/AuthAPI";
import AppPlaceholder from "../../utils/AppPlaceholder";
import IconMenu from "../../assets/images/icon-menu.png";
import {SVG_EDIT, SVG_EXPORT, SVG_LAYER_GROUP} from "../../utils/AppSvg";
import Modal from "../modal";
import ModalContainer from "../modal_container";
import FormMenu from "../form/menu";
import HandlerStatusArchive from "../../constants/handle-status-archive";
import {getFileUrl} from "../../utils/Utils";
import HandlerRemoveFile from "../../constants/handle-remove-file";

const RenderMenu = ({data, handlerRefreshData}) => {
    const [selectedModal, setSelectedModal] = useState(null);

    let item = data.single ?? null;
    let list_menu_domain = data.list_menu_domain ?? null;
    let list_menu_category = data.list_menu_category ?? null;
    let image_menu_logo = getFileUrl(item, 'url_menu_logo', IconMenu);
    let image_menu_cover = getFileUrl(item, 'url_menu_cover', IconMenu);
    const [checkIsActive, setCheckIsActive] = useState(!!item.is_active);

    function submitStatusActive(e, id) {
        e.preventDefault();
        let token = localStorage.getItem("token") ?? null;
        let tokenStore = localStorage.getItem("store_id_selected") ?? null;
        let dataForm = {ref_id: id, type: 'menu'};
        AuthAPI.statusActive(token, tokenStore, dataForm).then(r => setCheckIsActive(!checkIsActive));
    }

    return (
        <>
            <div className={"flex-center row"}>
                <div className={"col-auto w-100"}>
                    <div className="mb-3 card">
                        <div className="position-relative card-body text-center p-0">
                            <img src={image_menu_cover} className="rounded-1 zone-image zone-rectangle-2 w-100" alt={data.name}/>
                            {
                                image_menu_cover !== IconMenu &&
                                <HandlerRemoveFile handlerRefreshData={handlerRefreshData} type={'cover'} />
                            }
                        </div>
                    </div>
                </div>
                <div className={"col-auto"}>
                    <div className="mb-3 card">
                        <div className="position-relative card-body text-center p-0">
                            <img src={image_menu_logo} className="rounded-1 zone-image zone-square zone-square-165" alt={data.name}/>
                            {
                                image_menu_logo !== IconMenu &&
                                <HandlerRemoveFile handlerRefreshData={handlerRefreshData} type={'logo'} />
                            }
                        </div>
                    </div>
                </div>
                <div className={"col-auto flex-1"}>
                    <div className="mb-3 card">
                        <div className="overflow-hidden card-body">
                            <div className="d-flex">
                                <div className="px-3">
                                    <SVG_LAYER_GROUP/>
                                </div>
                                <div className="fs--1 ms-2 flex-1">
                                    <h5 className="fs-0 text-capitalize">{item.name}</h5>
                                    <p className="fs--1 mt-1">Creato il {item.created_at}</p>
                                    <div>
                                        {
                                            list_menu_domain &&
                                            list_menu_domain.map((menu_domain,key) =>
                                                <div key={key.toString()} className="fs--2 badge badge-soft-success rounded-pill">{menu_domain.name} | {menu_domain.url}</div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="pe-3 col-auto">
                                    <label className="switch">
                                        <input type="checkbox" checked={checkIsActive} onChange={(e) => submitStatusActive(e,item.id)}/>
                                        <div className="slider"></div>
                                        <div className="slider-card">
                                            <div className="slider-card-face slider-card-front"></div>
                                            <div className="slider-card-face slider-card-back"></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end col-12 mt-3">
                                <div className="px-0 col-auto">
                                    <button type="button" className="px-2 px-sm-3 btn btn-falcon-default btn-sm" onClick={() => setSelectedModal(data)}>
                                        <SVG_EDIT/>
                                        <span className="d-none d-xl-inline-block ms-1">Modifica</span>
                                    </button>
                                </div>
                                <div className="px-3 col-auto">
                                    <Link to={"/pdf/" + item.id} target={"_blank"} rel="noreferrer" className="px-2 px-sm-3 btn btn-info btn-sm">
                                        <SVG_EXPORT />
                                        <span className="d-none d-xl-inline-block ms-1">PDF</span>
                                    </Link>
                                </div>
                                <div className="px-0 col-auto">
                                    <HandlerStatusArchive isArchive={(item.is_archive === 1)} typeHandle={'menu'} refId={item.id} item={item}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3 card">
                <div className="card-body">
                    <div className="row">
                        <div className="mb-4 mb-lg-0 col-lg-2 col-md-6">
                            <h6 className="mb-2">Informazioni</h6>
                            <p className="mb-0 fs--1">Categorie totali: {list_menu_category.length ?? 0}</p>
                        </div>
                        <div className="mb-4 mb-lg-0 col-lg-10 col-md-6">
                            <p className="fs--1 mt-1">{item.description ?? 'Nessuna descrizione presente'}</p>
                        </div>
                    </div>
                </div>
            </div>
            <ModalContainer>
                {
                    selectedModal &&
                    <Modal handleClose={() => setSelectedModal(null)} item={{name: 'menu', title: item.name, action: 'edit-menu'}}>
                        <div className={"modal-container card overflow-hidden"}>
                            <div className="card-header"><h4 className="font-alt fs-1 modal-title color-brand-dark col-12 text-center">Modifica Menu</h4></div>
                            <FormMenu id={item.id} handleClose={() => setSelectedModal(null)}/>
                        </div>
                    </Modal>
                }
            </ModalContainer>
        </>
    );
}

const SingleMenu = () => {
    const {id} = useParams();
    const global = useGlobalContext();
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let data = {menu_id: id};
            let responseData = await AuthAPI.menuItem(global.token, global.tokenStore, data);
            // convert data to json
            if (responseData.status === 200) setData(responseData.data);
        }

        fetchData().then(() => setLoadingData(false));
    }, [global.token, global.tokenStore, id, loadingData]);

    const handlerRefreshData = (type) => {
        async function refreshData() {
            let data = {ref_id: id, type: 'menu', type_column: type};
            await AuthAPI.removeMedia(global.token, global.tokenStore, data);
        }

        setLoadingData(true);
        refreshData(type).then(() => setLoadingData(false));
    }

    return (
        <>
            <div className={"row mt-3"}>
                <AppBreadcrumb>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/dashboard"}>Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to={"/dashboard/menu"} role="button" tabIndex="0">Lista
                            Menù</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Menù</li>
                    </ol>
                </AppBreadcrumb>
                <div className={"col-md-8"}>
                    {
                        loadingData
                            ?
                            <div className={"row"}>
                                <div className={"col-12 mb-3"}><AppPlaceholder/></div>
                                <div className={"col-12"}><AppPlaceholder/></div>
                            </div>
                            : <RenderMenu data={data.context} handlerRefreshData={handlerRefreshData}/>
                    }
                </div>
                <div className={"col-md-4"}>
                    <div className="card">
                        <div className="bg-light card-header">
                            <h6 className="mb-0">Le categorie</h6>
                        </div>
                        <div className="fs--1 border-bottom card-body">
                            <div className={"category-content"}>
                                {
                                    loadingData
                                        ? <AppPlaceholder classCSS={"card-no-box"}/>
                                        : data.context.list_menu_category.map((item, key) => {
                                            return (
                                                <header key={key.toString()}>
                                                    <Link to={"/menu-category/" + item.id} className={"text-uppercase text-white fw-bold"}>{item.name}</Link>
                                                </header>
                                            );
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleMenu;