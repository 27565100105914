import React from 'react';
import {Link} from "react-router-dom";
import HandlerAPILogout from "../../constants/handle-api-logout";

const NavbarCard = (props) => {
    if (props.type === 'notification') {
        return (
            <div aria-labelledby={props.type} data-bs-popper="static"
                 className={"dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg dropdown-menu " + ( props.card_selected === 1 ? 'show' : '')}>
                <div className="dropdown-menu-notification dropdown-menu-end shadow-none card">
                    <div className="card-header card-header">
                        <div className="align-items-center row">
                            <div className="col"><h6 className="mb-0">Notifications</h6></div>
                            <div className="text-right col-auto">
                                <a className="card-link fw-normal" href="/dashboard/e-commerce#!">Mark all as read</a>
                            </div>
                        </div>
                    </div>
                    <div className="fw-normal fs--1 scrollbar list-group list-group-flush">
                        <div className="list-group-title">NEW</div>
                    </div>
                    <div className="card-footer text-center border-top">
                        <a className="card-link d-block" href="/dashboard/e-commerce#!">View all</a>
                    </div>
                </div>
            </div>
        );
    }
    else if (props.type === 'profile') {
        const {logoutAuth} = HandlerAPILogout();
        return (
            <div aria-labelledby={props.type} data-bs-popper="static"
                 className={"dropdown-caret dropdown-menu-card dropdown-menu-end dropdown-menu " + ( props.card_selected === 2 ? 'show' : '')}>
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    <Link to={"/settings/account"} className="dropdown-item">Account settings</Link>
                    <Link to={"/support"} className="dropdown-item">Support</Link>
                    <Link to={"/faq"} className="dropdown-item">FAQ</Link>
                    <hr className="dropdown-divider"/>
                    <Link to={"/settings/store"} className="dropdown-item">Store settings</Link>
                    <hr className="dropdown-divider"/>
                    <p onClick={logoutAuth} className="dropdown-item mb-2 pb-0 text-danger" style={{cursor: 'pointer'}}>Logout</p>
                </div>
            </div>
        );
    }
}

export default NavbarCard;