import { useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import AppButton from "../../utils/AppButton";
import {SVG_BRANCH, SVG_DOWNLOAD, SVG_PRINT} from "../../utils/AppSvg";

const QrCode = ({name,url}) => {
    const qrRef = useRef();
    const downloadQRCode = (e) => {
        e.preventDefault();
        let canvas = qrRef.current.querySelector("canvas");
        let image = canvas.toDataURL("image/png");
        let anchor = document.createElement("a");
        anchor.href = image;
        anchor.download = 'qr-' + name.replace(/ /g,'').toLowerCase() + '.png';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    return (
        <div className="row">
            <div className={"col-12 flex-1"}>
                <div className="pt-3 pt-md-1 px-0 px-md-3">
                    <div className={"row d-flex mb-3"}>
                        <h5 className={"col-auto text-info pt-1"}><SVG_DOWNLOAD/></h5>
                        <div className={"col-auto flex-1 ps-0 text-wrap text-700 fst-italic"}>Scarica l'immagine QR-Code del tuo menù digitale.</div>
                    </div>
                    <div className={"row d-flex mb-3"}>
                        <h5 className={"col-auto text-info pt-1"}><SVG_PRINT/></h5>
                        <div className={"col-auto flex-1 ps-0 text-wrap text-700 fst-italic"}>Stampa il QR-Code per promuovere il tuo menù.</div>
                    </div>
                    <div className={"row d-flex"}>
                        <h5 className={"col-auto text-info pt-1"}><SVG_BRANCH/></h5>
                        <div className={"col-auto flex-1 ps-0 text-wrap text-700 fst-italic"}>Visualizza i dati su quali sono i piatti più amati dai tuoi clienti.</div>
                    </div>
                </div>
            </div>
            <div className="col-auto m-auto">
                <div ref={qrRef} className={"card-qr p-3 rounded bg-success"}>
                    <QRCodeCanvas id="qrCode" value={url} bgColor={"#00d27a"} level={"H"} style={{aspectRatio: '1/1'}}/>
                </div>
            </div>
            <div className="col-12 mt-3">
                <AppButton type={"button"} class={"btn-outline-info w-100"} action={downloadQRCode}><SVG_DOWNLOAD/> Scarica il tuo QR code</AppButton>
            </div>
        </div>
    );
};

export default QrCode;