import React, {useEffect, useRef} from "react";
import useGooglePlaceAutoComplete from "../services/google_place_autocomplete";

const AppAutoComplete = (props) => {
    const inputRef = useRef();
    const serviceGoogleAutoComplete = useGooglePlaceAutoComplete();
    let autoComplete = "";

    const handleAddressSelect = async () => {
        let addressObj = await serviceGoogleAutoComplete.getFullAddress(autoComplete);
        inputRef.current.value = (addressObj.status) ? addressObj.context?.full_address : '';
        props.handlerData(addressObj.context);
    };

    useEffect(() => {
        async function loadGoogleMaps() {
            autoComplete = await serviceGoogleAutoComplete.initAutoComplete(inputRef.current, handleAddressSelect);
        }

        loadGoogleMaps().then();
    }, []);

    return (
        <input ref={inputRef} type={"text"}
               className={"form-control " + props.class}
               style={!props.status ? {opacity: 1} : {opacity: 0.5}}
               placeholder={!props.status ? (props.placeHolder) : ''}/>
    );
};

export default AppAutoComplete;

/*<input
    id={props.labelFor}
    type={props.type}
    name={props.labelFor}
    className={"form-control " + props.class}/>
 */