function InputValidation(value, type, fieldName = '') {
    //const regexEmail = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const regexPassword = new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'); // /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
    const regexEmail = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
    const regexUrl = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&=]*)/g);

    let validation;
    try {
        switch (type) {
            case 'email':
                validation = regexEmail.test(String(value).toLowerCase()) && value.length > 0 ? true : "Inserisci una email corretta";
                break
            case 'password':
                validation = regexPassword.test(String(value)) ? true : "La password deve contenere almeno 8 caratteri, una maiuscola, una minuscola ed un simbolo";
                break;
            case 'password_confirm':
                let valuePassword = value[0];
                let valuePasswordConfirm = value[1];
                validation = regexPassword.test(String(valuePasswordConfirm)) ? true : "La password deve contenere almeno 8 caratteri, una maiuscola, una minuscola ed un simbolo";
                if (validation === true) validation = (valuePassword === valuePasswordConfirm) ? true : "Le due password devono essere uguali";
                break;
            case 'array':
            case 'text':
                validation = (value.toString().length > 0) ? true : ('Il campo ' + fieldName + ' non può essere vuoto');
                break;
            case 'url':
                if (value.length === 0) throw('Il campo ' + fieldName + ' non può essere vuoto');
                if (value.includes('https')) throw('Rimuovere https dalla url');
                if (value.includes('http')) throw('Rimuovere http dalla url');
                if (value.includes('//')) throw('Rimuovere // dalla url');
                validation = regexUrl.test(String(value).toLowerCase()) ? true : "Inserisci un url corretta";
                break;
            default:
                validation = true;
                break;
        }
    } catch (e) {
        validation = e.toString();
    }
    return validation;
}

export default InputValidation;
