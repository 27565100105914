import './index.css';
import React, {useEffect, useState} from "react";
import AppInput from "../../utils/AppInput";
import {SVG_BULLET_POINT_CUSTOM} from "../../utils/AppSvg";
import {AuthAPI} from "../../apis/AuthAPI";
import CardDefault from "../../utils/CardDefault";
import AppButton from "../../utils/AppButton";
import MessageBanner from "../../utils/MessageBanner";
import {useGlobalContext} from "../../constants/application-context";
import InputValidation from "../../constants/input-validation";
import HandlerAPIMessage from "../../constants/handle-api-message";
import Dropzone from "../dropzone";
import AppPlaceholder from "../../utils/AppPlaceholder";
import {getFileUrl} from "../../utils/Utils";

const LayoutListDomain = ({type, data, currentData = null, changeEvent}) => {
    if (data !== null && data.length > 0) {
        let jsonCurrentData = currentData !== null ? JSON.parse(currentData) : [];
        return (
            <>
                {
                    data.map((item, key) =>
                        <div key={key.toString()} data-name={"list_menu_domain_id"} data-value={item.id}
                             onClick={changeEvent}
                             className={"d-flex align-items-center rounded-3 bg-light p-3 mb-2 border cursor-pointer " + ((jsonCurrentData.some(jsonItem => parseInt(item.id) === parseInt(jsonItem))) ? 'border-primary' : '')}>
                            <h6 className="mb-0 fw-bold">{item.name}</h6>
                        </div>
                    )
                }
            </>
        );
    } else {
        return (
            <CardDefault type={type}>
                <p className={"mb-0"}>Devi prima creare un dominio per i tuoi menù</p>
            </CardDefault>
        );
    }
}

const FormMenu = (props) => {
    let id = props.id ?? null;
    const global = useGlobalContext();
    const [apiStatus, setApiStatus] = useState({})
    const [onSubmitForm, setOnSubmitForm] = useState(false)
    const [submitFormStatus, setSubmitFormStatus] = useState(false)
    const [isDataComplete, setIsDataComplete] = useState(false)
    const [previewLogo, setPreviewLogo] = useState({})
    const [previewCover, setPreviewCover] = useState({})
    const [listMenuDomain, setListMenuDomain] = useState({})
    const [dataMenu, setDataMenu] = useState({
        id: '',
        name: '',
        description: '',
        file_logo: '',
        file_cover: '',
        list_menu_domain_id: '',
    });

    useEffect(() => {
        async function fetchData() {
            let responseDataMenu, responseDataDomainList;
            if (id !== null) {
                responseDataMenu = await AuthAPI.menuItem(global.token, global.tokenStore, {menu_id: id});
                if (responseDataMenu.status === 200) {
                    let data_list_menu_domain_id = [];
                    let data = responseDataMenu.data.status ? responseDataMenu.data.context.single : null;
                    let list_menu_domain = responseDataMenu.data.status ? responseDataMenu.data.context.list_menu_domain : null;
                    if (list_menu_domain) list_menu_domain.map(item => data_list_menu_domain_id.push(item.id.toString()));

                    let image_logo = getFileUrl(data, 'url_menu_logo');
                    let image_cover = getFileUrl(data, 'url_menu_cover');
                    if (image_logo !== null) setPreviewLogo(image_logo);
                    if (image_cover !== null) setPreviewCover(image_cover);

                    setDataMenu({
                        ...dataMenu,
                        id: data.id,
                        name: data.name,
                        description: data.description,
                        list_menu_domain_id: ((data_list_menu_domain_id.length > 0) ? JSON.stringify(data_list_menu_domain_id) : ''),
                    });
                }
            }
            responseDataDomainList = await AuthAPI.menuDomainList(global.token, global.tokenStore);
            return (responseDataDomainList.status === 200) ? responseDataDomainList.data : null;
        }

        fetchData().then((responseContext) => {
            setIsDataComplete(responseContext !== null);
            if (responseContext !== null) {
                setListMenuDomain(responseContext.status ? responseContext.context.list : null);
            }
        });
    }, [global.token, global.tokenStore]);

    function handleData(e) {
        //let currentType = e.target.type;
        let currentName = (e.currentTarget.tagName === 'DIV') ? e.currentTarget.dataset.name : e.target.name;
        let currentValue = (e.currentTarget.tagName === 'DIV') ? e.currentTarget.dataset.value : e.target.value;

        if (e.currentTarget.type === 'file') currentValue = e.target.files[0];

        if (e.currentTarget.tagName === 'DIV') {
            if (e.currentTarget.classList.contains('border-primary')) e.currentTarget.classList.remove('border-primary');
            else e.currentTarget.classList.add('border-primary');
        }

        switch (currentName) {
            case 'name':
                setDataMenu({...dataMenu, name: currentValue});
                break;
            case 'description':
                setDataMenu({...dataMenu, description: currentValue});
                break;
            case 'file_cover':
                setDataMenu({...dataMenu, file_cover: currentValue});
                // Generate Preview COVER
                const objectUrlCover = URL.createObjectURL(currentValue);
                setPreviewCover(objectUrlCover);
                return () => URL.revokeObjectURL(objectUrlCover);
                //break;
            case 'file_logo':
                setDataMenu({...dataMenu, file_logo: currentValue});
                // Generate Preview LOGO
                const objectUrlLogo = URL.createObjectURL(currentValue);
                setPreviewLogo(objectUrlLogo);
                return () => URL.revokeObjectURL(objectUrlLogo);
            //break;
            case 'list_menu_domain_id':
                let currentList = (dataMenu.list_menu_domain_id.length === 0) ? [] : JSON.parse(dataMenu.list_menu_domain_id);
                let currentKey = currentList.indexOf(currentValue);
                if (currentKey === null || currentKey === -1) currentList.push(currentValue);
                else currentList.splice(currentKey, 1);

                currentValue = JSON.stringify(currentList);
                setDataMenu({...dataMenu, list_menu_domain_id: currentValue});
                break;
            default:
                console.info('No type for ' + currentName);
                break;
        }
    }

    function handleRemoveDataFile(e, type) {
        if (type === 'logo') {
            setDataMenu({...dataMenu, file_logo: ''});
            setPreviewLogo({});
            e.target.value = '';
        }
        else if(type === 'cover') {
            setDataMenu({...dataMenu, file_cover: ''});
            setPreviewCover({});
            e.target.value = '';
        }
    }

    const formSubmit = async e => {
        setOnSubmitForm(true);
        e.preventDefault();
        let token = localStorage.getItem("token") ?? null;
        let tokenStore = localStorage.getItem("store_id_selected") ?? null;
        let validateName = InputValidation(dataMenu.name, 'text');
        //let validateDescription = InputValidation(dataMenu.description, 'text', 'Descrizione');
        let validateListDomainId = InputValidation(dataMenu.list_menu_domain_id, 'text');
        if (validateName === true && validateListDomainId === true) {
            let dataForm = {
                menu_id: dataMenu.id ?? null,
                name: dataMenu.name,
                description: dataMenu.description,
                file: dataMenu.file_logo,
                file_cover: dataMenu.file_cover,
                list_menu_domain_id: dataMenu.list_menu_domain_id
            };
            let response = await AuthAPI.menuSave(token, tokenStore, dataForm);
            if (response.status === 200) {
                if (response.data.status) {
                    global.setDataUpdate(!global.dataUpdate);
                    let responseContext = response.data.context !== null ? response.data.context : null;
                    let responseMessage = response.data.message !== null ? response.data.message : null;
                    if (responseContext === null || responseContext.length === 0) setApiStatus(HandlerAPIMessage(5, 'Errore imprevisto'));
                    else setApiStatus(HandlerAPIMessage(3, responseMessage));
                    setSubmitFormStatus(response.data.status);
                } else setApiStatus(HandlerAPIMessage(5, response.data.message));
            } else setApiStatus(HandlerAPIMessage(5, 'Impossibile procedere, non c\'è nessuna connessione ad internet'))
        }
        else {
            if (validateName !== true) setApiStatus(HandlerAPIMessage(5, validateName));
            else if (validateListDomainId !== true) setApiStatus(HandlerAPIMessage(5, validateListDomainId));
        }
        setOnSubmitForm(false);
    }

    if (!isDataComplete) return (<AppPlaceholder rows={10} classCSS={"card-no-box"} />);

    if (listMenuDomain && listMenuDomain.length === 0) {
        return (
            <div className={"p-5 text-center"}>
                <div className="display-1 text-300 fs-error">OPS</div>
                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">Ancora pochi passi e poi potrai creare il tuo menù.</p>
                <hr/>
                <p className={"mb-0"}>Controlla di aver creato un tuo <b>dominio</b> per i menù.</p>
                <p>Se invece pensi ci sia un errore,<a href="mailto:team@gloowe.com" className="ms-1">contattaci</a>.</p>
            </div>
        );
    }

    return (
        <>
            {
                (!submitFormStatus)
                    ?
                    <form onSubmit={formSubmit}>
                        <div className={"mb-3 row"}>
                            <div className={"col-12 mb-3"}>
                                <AppInput
                                    type={"input"}
                                    structure={"field-vertical"}
                                    labelFor={"name"} content={"Nome"}
                                    class={"is-medium"}
                                    changeEvent={(e) => handleData(e)}
                                    placeHolder={"Scegli un nome per il menù"}
                                    value={dataMenu.name}
                                />
                            </div>
                            <div className={"col-sm-12 col-md-4 mb-3"}>
                                <label className="form-label">Foto del menù</label>
                                <Dropzone preview={previewLogo} inputFileName={"file_logo"}
                                          typeZone={'zone-square zone-square-200'}
                                          fileCoverName={dataMenu.file_logo.name}
                                          onInputChange={(e) => handleData(e)}
                                          onInputRemoveFile={(e) => handleRemoveDataFile(e, 'logo')}>
                                    <div className="d-flex justify-content-center">
                                        <p className="fs-0 mb-0 text-700">Aggiungi un logo al menù</p>
                                    </div>
                                    <p className="mb-0 w-75 mx-auto text-400">Ti consigliamo di caricare un file da 300x300 px e max 5MB</p>
                                </Dropzone>
                            </div>
                            <div className={"col-sm-12 col-md-8 mb-3"}>
                                <label className="form-label">Copertina del menù</label>
                                <Dropzone preview={previewCover} inputFileName={"file_cover"}
                                          typeZone={'zone-rectangle-2'}
                                          fileCoverName={dataMenu.file_cover.name}
                                          onInputChange={(e) => handleData(e)}
                                          onInputRemoveFile={(e) => handleRemoveDataFile(e, 'cover')}>
                                    <div className="d-flex justify-content-center">
                                        <p className="fs-0 mb-0 text-700">Aggiungi una copertina per il menù</p>
                                    </div>
                                    <p className="mb-0 w-75 mx-auto text-400">Ti consigliamo di caricare un file da 820x312 px e max 5MB</p>
                                </Dropzone>
                            </div>
                            <div className={"col-12 mb-3"}>
                                <AppInput
                                    type={"textarea"}
                                    structure={"field-vertical"}
                                    labelFor={"description"} content={"Descrizione"}
                                    class={"is-medium"}
                                    rows={3}
                                    changeEvent={(e) => handleData(e)}
                                    placeHolder={"..."}
                                    value={dataMenu.description}
                                />
                            </div>
                            <div className={"col-12"}>
                                <div className="field">
                                    <label htmlFor="name" className="form-label">Associa il menù ad un dominio</label>
                                    <div className={"simple-bar-content-wrapper"} style={{overflowX: "scroll"}}>
                                        <div className="control d-flex inline-size-max-content">
                                            <LayoutListDomain type={"list-domain"}
                                                              data={listMenuDomain}
                                                              currentData={(dataMenu && dataMenu.list_menu_domain_id !== undefined && dataMenu.list_menu_domain_id.length > 0 ? dataMenu.list_menu_domain_id : null)}
                                                              changeEvent={(e) => handleData(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <AppButton type={"submit"} disabled={onSubmitForm}
                                           class={"btn-primary " + (onSubmitForm ? 'is-loading' : '') + " is-block is-fullwidth is-medium"}>
                                    {!onSubmitForm ? (id ? "Modifica menù" : "Crea nuovo menù") : '...'}
                                </AppButton>
                            </div>
                            <div className={"col-12 pt-2"}>
                                <MessageBanner type={"banner"} is_fixed={false} has_closed={false} code={apiStatus.class} text={apiStatus.textMessage}/>
                            </div>
                        </div>
                    </form>
                    :
                    <div className="card-body text-center">
                        <h3 className="fw-semi-bold">{id ? 'Il menù è stato aggiornato' : 'Nuovo menù creato'}</h3>
                        <p>{apiStatus.textMessage}</p>
                        <div className="text-center">
                            <button type="button" className="my-3 btn btn-outline-secondary btn-sm" onClick={props.handleClose}>Chiudi</button>
                            <small className="d-block">For any technical issues faced, please contact <a href="mailto:team@gloowe.com">Customer Support</a>.</small>
                        </div>
                    </div>
            }
        </>
    );
}

export default FormMenu;