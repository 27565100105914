export function getFileUrl(data, key, imageFallBack = null) {
    let url;
    try {
        if (key.toString() in data) {
            url = JSON.parse(data[key.toString()]);
            if (url.length === 2) {
                url = ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development-') ? 'http://0.0.0.0' : 'https://www.gloowe.com') + url[1];
            }
        }
    } catch (e) {
        url = imageFallBack;
    }
    return url;
}

export function isPriceFloat(value) {
    return typeof parseFloat(value.trim()) === 'number' && !Number.isNaN(parseFloat(value.trim()));
}