import React from "react";

const AppBreadcrumb = (props) => {
    return (
        <div className={"col-12"}>
            <div className="mb-3 card">
                <div className="position-relative card-header bg-breadcrumb">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default AppBreadcrumb;