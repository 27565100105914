import './App.css';
import React, {Suspense} from "react";
import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import {useGlobalContext} from "./constants/application-context";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import SingleMenuCategory from "./components/dashboard_menu_category/single";
import SingleMenu from "./components/dashboard_menu/single";
import SingleMenuConsumable from "./components/dashboard_menu_consumable/single";
import Page404 from "./utils/Page404";
import ListMenuDomain from "./components/dashboard_domain/list";
import SingleDomain from "./components/dashboard_domain/single";
import ViewPDF from "./components/pdf";
import Recovery from "./components/recovery";
import RegisterRequest from "./components/register_request";
import LoadingPage from "./components/loadingpage";

const LazyLogin = React.lazy(() => import("./components/login"))
const LazyRegister = React.lazy(() => import("./components/register"))
const LazyListMenu = React.lazy(() => import("./components/dashboard_menu/list"));
const LazyListMenuCategory = React.lazy(() => import("./components/dashboard_menu_category/list"));
const LazyListMenuConsumable = React.lazy(() => import("./components/dashboard_menu_consumable/list"));
const LazyHomepage = React.lazy(() => import("./components/homepage"))
const LazyDashboard = React.lazy(() => import("./components/dashboard"))
const LazySettings = React.lazy(() => import("./components/settings"))
const LazyMenuOrdersAdd = React.lazy(() => import("./components/orders/add"))
const LazyListMenuOrder = React.lazy(() => import("./components/orders/list"));

const NoAuthRoute = ({children}) => {
    const global = useGlobalContext();
    const location = useLocation();
    if (global.token) return <Navigate to="/dashboard" replace state={{from: location}}/>;
    return children;
};

const ProtectedRoute = ({children}) => {
    const global = useGlobalContext();
    const location = useLocation();
    if (!global.token) {
        let toUrl = ((location !== null || undefined) && (location.pathname !== null || undefined)) ? '/login?ret=' + location.pathname : '/login';
        return <Navigate to={toUrl} replace state={{from: location}}/>;
    }

    return (
        <section className={"py-0"}>
            <div className={"container-fluid"}>
                <Navbar/>
                <div className={"content"}>
                    <Navbar type={"profile"}/>
                    {children}
                    <Footer/>
                </div>
            </div>
        </section>
    );
};

const App = () => {
    //let currentURL = window.location.href; // returns the absolute URL of a page
    //let pathname = window.location.pathname; //returns the current url minus the domain name
    //let urlWithoutNavBar = ["/login", "/signup", "/signup-business", "/recovery"];

    return (
        <Suspense fallback={<LoadingPage/>}>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<NoAuthRoute><LazyHomepage/></NoAuthRoute>}/>
                    <Route exact path="/login" element={<NoAuthRoute><LazyLogin/></NoAuthRoute>}/>
                    <Route exact path="/register" element={<NoAuthRoute><LazyRegister/></NoAuthRoute>}/>
                    <Route exact path="/register-request/" element={<NoAuthRoute><RegisterRequest/></NoAuthRoute>}/>
                    <Route exact path="/recovery" element={<NoAuthRoute><Recovery/></NoAuthRoute>}/>
                    <Route exact path="/settings/account" element={<ProtectedRoute><LazySettings/></ProtectedRoute>}/>
                    <Route exact path="/settings/security" element={<ProtectedRoute><LazySettings/></ProtectedRoute>}/>
                    <Route exact path="/settings/email-preferences" element={<ProtectedRoute><LazySettings/></ProtectedRoute>}/>
                    <Route exact path="/settings/store" element={<ProtectedRoute><LazySettings/></ProtectedRoute>}/>
                    <Route exact path="/settings/store-info" element={<ProtectedRoute><LazySettings/></ProtectedRoute>}/>
                    <Route exact path="/settings/team" element={<ProtectedRoute><LazySettings/></ProtectedRoute>}/>
                    <Route exact path="/settings/store-billing-plans" element={<ProtectedRoute><LazySettings/></ProtectedRoute>}/>
                    <Route exact path="/dashboard" element={<ProtectedRoute><LazyDashboard/></ProtectedRoute>}/>
                    <Route exact path="/dashboard/menu" element={<ProtectedRoute><LazyListMenu/></ProtectedRoute>}/>
                    <Route exact path="/dashboard/menu-domain" element={<ProtectedRoute><ListMenuDomain/></ProtectedRoute>}/>
                    <Route exact path="/dashboard/menu-category" element={<ProtectedRoute><LazyListMenuCategory/></ProtectedRoute>}/>
                    <Route exact path="/dashboard/menu-consumable" element={<ProtectedRoute><LazyListMenuConsumable/></ProtectedRoute>}/>
                    <Route exact path="/orders/add" element={<ProtectedRoute><LazyMenuOrdersAdd/></ProtectedRoute>}/>
                    <Route exact path="/orders/list" element={<ProtectedRoute><LazyListMenuOrder/></ProtectedRoute>}/>
                    <Route path="/domain/:id" element={<ProtectedRoute><SingleDomain/></ProtectedRoute>}/>
                    <Route path="/menu/:id" element={<ProtectedRoute><SingleMenu/></ProtectedRoute>}/>
                    <Route path="/menu-category/:id" element={<ProtectedRoute><SingleMenuCategory/></ProtectedRoute>}/>
                    <Route path="/menu-consumable/:id" element={<ProtectedRoute><SingleMenuConsumable/></ProtectedRoute>}/>
                    <Route path="/pdf/:id" element={<ViewPDF/>}/>
                    <Route path="/*" element={<Page404/>}/>
                </Routes>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
